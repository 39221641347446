import React from "react";
import { Flex, Image } from "antd";

interface IconSetting {
    path: string,
    width: string,
    height: string,
}

export function MyIcon(iconSetting: IconSetting) {
    return (
        <Flex
            style={{ width: iconSetting.width, height: iconSetting.height }}
        >
            <Image
                src={iconSetting.path}
                preview={false}
            />
        </Flex>
    );
}

export default MyIcon
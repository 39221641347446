import { DatePicker } from "antd";

function CustomDatePicker(props: any) {
    return (
        <DatePicker
            inputReadOnly={true}
            placement={"topRight"}
            {...props}
        />
    );
}

export default CustomDatePicker;

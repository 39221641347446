import React, { useState } from 'react';
import { Badge, Calendar, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import type { BadgeProps, CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';

import './gridView.css'
import { EventDrawerGridView } from './drawer/GridViewEventDrawer';
import { localeConf } from './localeConf';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const formatDateTime = (dateToFormat: string) => {
    const [date, time] = dateToFormat.split(' ');
    const [day, month, year] = date.split('-');
    if (!time) {
        return dayjs(`${year}-${month}-${day}`)
    }

    return dayjs(`${year}-${month}-${day} ${time}`)
}

const getListData = (value: Dayjs, events: any, t: TFunction<"translation", undefined>) => {
    const listData: { type: string; content: string }[] = [
        { type: 'success', content: t("event-page.event") },
    ];

    const year = value.get('year');
    const month = value.get('month');
    const monthEvent = events.upcomingEvents[`${String(year)}-${String(month + 1).padStart(2, '0')}`];

    if (monthEvent) {
        for (const day of monthEvent) {
            if (value.isSame(formatDateTime(day.date), 'day')) {
                return listData;
            }
        }
    }
    return [];
};

function GridView(props: any) {
  const {t} = useTranslation();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerEvent, setDrawerEvent] = useState<{events: any, month: string}>();

  const dateCellRender = (value: Dayjs) => {
    const listData: { type: string; content: string }[] = getListData(value, props.events, t);

    return (
        <ul className="events">
            {listData.map((item) => (
                <Badge status={item.type as BadgeProps['status']} text={`${item.content}`} />
            ))}
        </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const handleChange = (date: Dayjs ) => {
    const monthEvent = props.events.upcomingEvents[date.format('YYYY-MM')];
    const dayEvent: any = [];

    if (monthEvent) {
        for (const day of monthEvent) {
            if (date.isSame(formatDateTime(day.date), 'day')) {
                dayEvent.push(day);
            }
        }
    }
    setDrawerEvent({events: dayEvent, month: date.format('YYYY-MM-DD')})
    if (dayEvent.length > 0) {
        setOpenDrawer(true);
    }
  }

  const onDrawerClose = () => {
    setTimeout(() => {
      setOpenDrawer(false)
    }, 250);
  }

  return (
    <div style={{ margin: '0 auto', marginTop: '20px', width: '70vw',  }}>
      {openDrawer && <EventDrawerGridView onClose={onDrawerClose} events={drawerEvent} renew={props.renew}/>}
      <Calendar
        style={{ border: 'none', backgroundColor: 'rgba(197, 194, 194, 0.2)', borderRadius: '15px' }}
        cellRender={cellRender}
        onSelect={handleChange}
        mode='month'
        locale={localeConf}
      />
    </div>
  );
};

export default GridView;
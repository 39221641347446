import React, { useEffect, useState } from 'react';
import { Button, Collapse, ConfigProvider, Divider, Flex, Input, message, Popover, Skeleton, Space } from 'antd';

import './behaviors.css'
import InfoContainer from '../infoContainer/InfoContainer';
import { createBehavior, getBehaviorToday, getLastBehavior, getBehaviorAnalysis } from '../../request/behavior';
import { useGlobalState } from '../../state/globalState';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import BehaviorData from './BehaviorData';
import { fontSize } from '@mui/system';
import { useTranslation } from 'react-i18next';
import i18n from '../../lang/i18n';

interface Analysis {
    status: 'positive' | 'warning' | 'danger' | 'advice';
    messageFr: string;
    messageEn: string;
}

function Behaviors() {
    const {t} = useTranslation();
    const [reRender, setReRender] = useGlobalState('reRender');
    const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');
    const behaviorsData = BehaviorData();

    const initialState = behaviorsData.reduce((acc: any, key: any) => {
        acc[key] = false;
        return acc;
    }, {});

    const [openState, setOpenState] = useState<{[key: number]: boolean}>({});
    const [checked, setChecked] = useState<{[key: number]: boolean}>(initialState);

    const [other, setOther] = useState("");
    const [otherPast, setOtherPast] = useState("");
    const [isOtherChecked, setIsOtherChecked] = useState(false);

    const [behaviorsArray, setBehaviorsArray] = useState<string[]>([]);
    const [localBehaviorsArray, setLocalBehaviorsArray] = useState<string[]>([]);

    const [hasModification, setHasModification] = useState(false);

    const [analysis, setAnalysis] = useState<Analysis | null>(null);
    const [renderedMessage, setRenderedMessage] = useState<JSX.Element | null>(null);
    const [loading, setLoading] = useState(false);

    function handleBehaviorModification(label: string, toAdd: boolean) {
        setLocalBehaviorsArray(prevArray => {
            const behaviorExists = prevArray.some(item => item.startsWith(label));

            if (toAdd && !behaviorExists) {
                return [...prevArray, label];
            } else if (!toAdd || behaviorExists) {
                return prevArray.filter(item => !item.startsWith(label));
            } else {
                return prevArray;
            }
        });
    }

    const handleOtherChange = (value: string) => {
        setOther(value);
    }


    const handleOpenChange = (key: number, open: boolean) => {
        setOpenState(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = open;
            return newState;
        });
    };

    function handleEverythingIsFine(key: number, val: any) {
        setLocalBehaviorsArray(prevArray => {
            const filteredArray = prevArray.filter(item => item.endsWith("++") && item !== t("behaviors.list.scratching++") && item !== t("behaviors.list.licking++"));
            if (!filteredArray.includes(t("behaviors.list.everything-is-fine")))
                filteredArray.push(t("behaviors.list.everything-is-fine"));
            return filteredArray;
        });

        setChecked(prevState => {
            const newState = { ...prevState };

            behaviorsData.forEach((behavior, index) => {
                if (behavior.label === t("behaviors.list.everything-is-fine") || localBehaviorsArray.some(item => item.startsWith(behavior.label) && item.endsWith("++") && behavior.id !== 11 && behavior.id !== 12)) {
                    newState[index] = true;
                } else {
                    newState[index] = false;
                }
            });

            return newState;
        });
        setIsOtherChecked(false);
    }

    const handleClick = (key: number, val: any) => {
        setHasModification(true);

        if (val.label === t("behaviors.list.everything-is-fine") && !checked[key])
            return handleEverythingIsFine(key, val);
        if (!val.key) {
            setChecked(prevState => {
                const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                    acc[index] = checked[index];
                    return acc;
                }, {});
                newState[key] = !checked[key];
                return newState;
            });
        }
        if (val.key) {
            handleBehaviorModification(val.key, true);
        } else {
            if (val.label === t("behaviors.list.other"))
                setIsOtherChecked(!isOtherChecked);
            else
                handleBehaviorModification(val.label, !val.details);
        }
    };

    const detailActive = (val: any) => {
        if (val.details) {
            return !!localBehaviorsArray.find(item => item.startsWith(val.label));
        } else {
            return true;
        }
    }

    const isEverythingFine = (label: string) => {
        const validLabels = [
            t("behaviors.list.everything-is-fine"),
            t("behaviors.list.hunger"),
            t("behaviors.list.hydration"),
            t("behaviors.list.energy")
        ];

        if (localBehaviorsArray.includes(t("behaviors.list.everything-is-fine")) && label !== t("behaviors.list.everything-is-fine")
            && !(validLabels.includes(label) && (localBehaviorsArray.includes(`${label} ++`) || !!!localBehaviorsArray.find(item => item.startsWith(label))))) {
            return true
        }
        return false;
    }

    function getBehaviorsWithId(behaviors: number[], data: any) {
        const result: string[] = [];
        let label: string;

        behaviors.forEach((id) => {
            label = "";
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === id) {
                    label = data[i].label;
                    break;
                }
                if (data[i].details) {
                    for (let j = 0; j < data[i].details.length; j++) {
                        if (data[i].details[j].id === id) {
                            label = data[i].details[j].key;
                            break;
                        }
                    }
                }
            }
            if (label !== "")
                result.push(label);
        });

        return result;
    }

    const getSelectedBehavior = async () => {
        const result = await getLastBehavior();

        if (result && (result.behaviors)) {
            const behaviorsString = getBehaviorsWithId(result.behaviors, behaviorsData);
            setChecked(prevState => {
                const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                    acc[index] = prevState[index];
                    return acc;
                }, {});
                behaviorsData.forEach((val, key: number) => {
                    const match = behaviorsString.some((behavior: string) =>
                        behavior.startsWith(val.label)
                    );
                    newState[key] = match;
                });
                if (result.other !== "") {
                    setIsOtherChecked(true);
                    newState[Object.keys(newState).length - 2] = true;
                }
                return newState;
            });
            setBehaviorsArray(behaviorsString || []);
            setLocalBehaviorsArray(behaviorsString || []);
            setOtherPast(result.other || "");
            setOther(result.other || "");
        }
    }

    async function getBehaviorAnalysisMessage()
    {
        const analysisBehavior = await getBehaviorAnalysis();
        setAnalysis(analysisBehavior);
        return analysisBehavior;
    }

    function getAssociatedIds(array: string[], data: any) {
        const result: number[] = [];
        let id: number = -1;
        array.forEach((item) => {
            id = -1;
            for (let i = 0; i < data.length; i++) {
                if (data[i].label === item) {
                    id = data[i].id;
                    break;
                }
                if (data[i].details) {
                    for (let j = 0; j < data[i].details.length; j++) {
                        if (data[i].details[j].key === item) {
                            id = data[i].details[j].id;
                            break;
                        }
                    }
                }
            }
            if (id !== -1) {
                result.push(id);
            }
        });
        return result;
    }

    async function validateBehaviors()
    {
        try {
            if (localBehaviorsArray.length > 0 && hasModification)
                await createBehavior(getAssociatedIds(localBehaviorsArray, behaviorsData), other);
        } catch(error: any) {
            console.log(`there was an error: ${error}`);
            message.error(t('behaviors.validation-error'));
        }
        setReRender(!reRender);
        message.loading(t('behaviors.ongoing-analysis'), 2);
        setLoading(true);
        setTimeout(async () => {
            try {
                const analysisBehavior = await getBehaviorAnalysisMessage();
                const messageDisplayed = localStorage.getItem("savedLanguage") === "fr" ? analysisBehavior.messageFr : analysisBehavior.messageEn;
                if (analysisBehavior && analysisBehavior.status === "positive") {
                    message.success(`${t("behaviors.analysis.positive")} : ${messageDisplayed}`);
                } else if(analysisBehavior.status === "warning") {
                    message.warning(`${t("behaviors.analysis.warning")} : ${messageDisplayed}`);
                } else if(analysisBehavior.status === "danger") {
                    message.error(`${t("behaviors.analysis.danger")} : ${messageDisplayed}`);
                } else if (analysisBehavior.status === "advice") {
                    message.info(`${t("behaviors.analysis.advice")} : ${messageDisplayed}`);
                }
                setLoading(false);
            } catch(error: any) {
                console.log(`there was an error: ${error}`);
                message.error(t("behaviors.analysis-error"));
                setLoading(false);
            }
        }, 2000);
    }

    const renderAnalysisMessage = (analysisBehavior: Analysis | null) => {
        if (!analysisBehavior) return null;

        const iconMapping = {
            positive: <CheckCircleOutlined style={{ color: 'green' }} />,
            advice: <InfoCircleOutlined style={{ color: 'blue' }} />,
            warning: <WarningOutlined style={{ color: 'orange' }} />,
            danger: <CloseCircleOutlined style={{ color: 'red' }} />,
        };

        const statusLabels = {
            positive: t("behaviors.analysis.positive"),
            advice: t("behaviors.analysis.advice"),
            warning: t("behaviors.analysis.warning"),
            danger: t("behaviors.analysis.danger"),
        };

        const messageDisplayed = localStorage.getItem("savedLanguage") === "fr" ? analysisBehavior.messageFr : analysisBehavior.messageEn;

        return (
            <Flex align='top' justify='flex-start' gap={'small'}>
                <p style={{fontSize: "18px"}}>
                    {iconMapping[analysisBehavior.status]}
                </p>
                <p style={{fontSize: "18px"}}>
                    <b>{statusLabels[analysisBehavior.status]}: </b>
                    {messageDisplayed}
                </p>
            </Flex>
        );
    };

    useEffect(() => {
        getSelectedBehavior();
        getBehaviorAnalysisMessage();
    }, [reRender]);

    useEffect(() => {
        setRenderedMessage(renderAnalysisMessage(analysis));
    }, [analysis]);

    useEffect(() => {
        setHasModification(localBehaviorsArray.sort().join(',') !== behaviorsArray.sort().join(',') || other !== otherPast);
    }, [localBehaviorsArray, other]);

    useEffect(() => {
        if (!isOtherChecked)
            setOther("");
    }, [isOtherChecked]);

  return (
    <ConfigProvider
        theme={{
            components: {
                Button: {
                    colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
                }
            }
        }}
    >
        <InfoContainer
            cardTitle={t("behaviors.title", {name: selectedPetName})}
            extra={
                <Flex justify='flex-end' align='center' gap={"small"}>
                    <Button
                        type='link'
                        shape='circle'
                        icon={<ReloadOutlined className='rotate-on-hover'/>}
                        onClick={async () => await getSelectedBehavior()}
                        disabled={!hasModification}
                    />
                    <Button
                        type='default'
                        onClick={() => validateBehaviors()}
                        disabled={localBehaviorsArray.length === 0 || !hasModification}
                    >
                        {t("behaviors.validate")}
                    </Button>
                </Flex>
            }
        >
            <div className='behaviors' style={{ textAlign: 'center' }}>
                {behaviorsData && behaviorsData.map((val, key) => {
                    return (
                        <Popover
                            key={key}
                            trigger='click'
                            content={val.details &&
                                <Flex style={{ flexDirection: 'row' }} gap={5}>
                                    {val.details.map(detail => (
                                        <Button
                                            type='dashed'
                                            key={detail.key}
                                            className={`behaviors-detail ${isEverythingFine("") && detail.label !== '++' ? "disabled" : ""}`}
                                            onClick={() => {
                                                handleClick(key, detail)
                                                handleOpenChange(key, false)
                                            }}
                                            style={{ borderColor: 'rgb(116, 113, 114)' }}
                                            disabled={isEverythingFine("") && detail.label !== '++'}
                                        >
                                            {detail.label}
                                        </Button>
                                    ))}
                                </Flex>
                            }
                            open={!val.details ? false : (checked[key] ? openState[key] : false)}
                            onOpenChange={(open) => {
                                handleOpenChange(key, open)
                            }}
                        >
                            <Button
                                type='default'
                                className={`behaviors-button
                                    ${(isEverythingFine(val.label)
                                            ? "disabled"
                                            : (checked[key] && detailActive(val)
                                                ? "active"
                                                : "")
                                    )}`
                                }
                                key={key}
                                onClick={() => handleClick(key, val)}
                                disabled={isEverythingFine(val.label)}
                            >
                                {localBehaviorsArray.find(item => item.startsWith(val.label)) || val.label}
                            </Button>
                        </Popover>
                    );
                })}
                {isOtherChecked &&
                    <Space.Compact style={{ marginTop: '15px', width: '100%' }}>
                        <Input
                            addonBefore={t("behaviors.list.other")}
                            value={other}
                            onChange={(e) => handleOtherChange(e.target.value)}
                        />
                    </Space.Compact>
                }
            </div>
            {loading ? <Skeleton.Input block active /> : renderedMessage}
        </InfoContainer>
    </ConfigProvider>
  );
}

export default Behaviors;

import axios from 'axios';

const getSpecificUser = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user`,
            {
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

const updateUser = async (firstname: string, username: string, phoneNumber: string) => {
    try {
        const data = JSON.stringify({firstname, username, phoneNumber}, null, 1)
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const updateUserEmail = async (email: string) => {
    try {
        const data = JSON.stringify({email}, null, 1)
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/email`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const updateUserPassword = async (password: string, old_password: string) => {
    try {
        const data = {
            password: password,
            oldPassword: old_password
        }
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/password`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}


export {
    getSpecificUser,
    updateUser,
    updateUserEmail,
    updateUserPassword
}

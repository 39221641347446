import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Flex, message, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function ImagesUploader(props: {style?: React.CSSProperties, setFileList: React.Dispatch<React.SetStateAction<any[]>>, fileList: any[]}) {
    const [size, setSize] = useState(0);
    const {t} = useTranslation();

    async function handleNewImage(file: any) {
        const fileBuffer = file;
        const reader = new FileReader();

        const readFile = (file: any) => {
            return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        };

        try {
            const result = await readFile(fileBuffer);
            file.urlBase64 = result;

            if (size + file.size > 5000000) {
                message.warning(t("feedback.upload.warning-size"));
                return false;
            }

            console.log(file);
            props.setFileList((prevFileList) => [...prevFileList, file]);
        } catch (error) {
            message.error(t("feedback.upload.error-reader"));
            return false;
        }

        return false;
    }

    useEffect(() => {
        let size = 0;
        props.fileList.forEach((file) => {
            size += file.size;
        });
        setSize(size);
    }, [props.fileList]);

    return (
        <>
            <Flex vertical style={props.style}>
                <Flex align="center" gap={"small"}>
                    <Upload
                        accept="image/*"
                        multiple
                        beforeUpload={(file) => handleNewImage(file)}
                        disabled={size >= 5000000}
                    >
                        <Button
                            icon={<UploadOutlined />}
                            disabled={size >= 5000000}
                        >
                            {t("feedback.upload.button-text")}
                        </Button>
                    </Upload>
                    {(size / 1000000).toFixed(2)} Mo / 5 Mo
                </Flex>
                {props.fileList.length > 0 && (
                    (props.fileList.map((file: any) => (
                        <Flex align="center" style={{margin: "5px"}} gap={"small"} justify="space-between">
                            <div>
                                <PaperClipOutlined style={{marginRight: "5px"}}/>
                                {file.name}
                            </div>
                            <Button
                                danger
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => props.setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid))}
                            />
                        </Flex>
                    )))
                )}
            </Flex>
        </>
    )
}

export default ImagesUploader;

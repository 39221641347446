import { Checkbox, Flex, Form, InputNumber, message, Modal, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../config/format";
import { formatDate } from "../drawer/TreatmentDrawer";
import { createTreatment, validateTreatment } from "../../../request/events";
import CustomDatePicker from "../../customPicker/CustomDatePicker";

function TreatmentValidationModal(props: any) {
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [validationDate, setValidationDate] = useState(formatDate(props.event.date));
    const [renewTreatment, setRenewTreatment] = useState(true);
    const [treatmentDuration, setTreatmentDuration] = useState(props.event.duration.number);
    const [durationUnit, setDurationUnit] = useState(props.event.duration.unit);

    const [nextTreatmentDate, setNextTreatmentDate] = useState('');

    function onValidationOk() {
        form.submit();
    }

    function onValidationCancel() {
        props.onFinish(false);
    }

    async function handleSubmit() {
        try {
            await validateTreatment(props.event._id);
            if (renewTreatment) {
                const payload = {
                    pet: {
                        pet_id: props.event.pet.pet_id
                    },
                    name: props.event.name,
                    date: validationDate.format(dateFormat),
                    duration: { number: treatmentDuration, unit: durationUnit },
                    every: {number: 1, unit: 'day'},
                    treatment: props.event.treatment,
                    notes: props.event.notes
                }
                await createTreatment(payload)
            }
            message.success(t("treatment-renew.success"));
        } catch(err: any) {
            props.onFinish(false);
            message.error(t("treatment-renew.error"));
            return false;
        }
        props.onFinish(true);
    }

    useEffect(() => {
        if (renewTreatment) {
            if (validationDate != null) {
                const nextDate = validationDate.add(treatmentDuration, durationUnit);
                setNextTreatmentDate(nextDate.format(dateFormat));
            } else {
                setNextTreatmentDate("00-00-0000");
            }
        }
    }, [renewTreatment, treatmentDuration, durationUnit, validationDate]);

    useEffect(() => {
        form.setFieldsValue({ date: validationDate });
    }, []);

    return (
        <>
            <Modal
                title={t("treatment-renew.validate-treatment")}
                open={props.open}
                onOk={onValidationOk}
                onCancel={onValidationCancel}
                okText={t("form.confirm")}
                cancelText={t("form.cancel")}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        style={{ maxWidth: 600}}
                        form={form}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={handleSubmit}
                        variant='filled'
                        clearOnDestroy
                    >
                        {dom}
                    </Form>
                )}
            >
                <Form.Item label={t("treatment-renew.validation-date")}
                    name="date"
                    rules={[{ required: true, message: t("appointment-creation.date-placeholder") }]}
                    initialValue={validationDate}
                >
                    <CustomDatePicker
                        placeholder={t("appointment-creation.date-placeholder")}
                        style={{ width: '100%' }}
                        onChange={(e: any) => setValidationDate(e as any)}
                        format={`${dateFormat}`}
                        value={validationDate}
                        disabledDate={(current: any) => current && current > new Date()}
                    />
                </Form.Item>

                <Checkbox
                    checked={renewTreatment}
                    onChange={(e) => setRenewTreatment(e.target.checked)}
                >
                    {t("treatment-renew.renew-treatment")}
                </Checkbox>
                {renewTreatment && (
                    <>
                        <Form.Item
                            style={{ marginTop: '20px', marginBottom: '0' }}
                            label={t("treatment-reminder-creation.validity-duration")}
                        >
                            <Flex style={{ flexDirection: 'row' }} gap={'4%'}>
                                <Form.Item
                                    name="durationNumber"
                                    style={{ width: '60%' }}
                                >
                                    <InputNumber
                                        className="calendar-number-input"
                                        defaultValue={props.event.duration.number} min={1}
                                        onChange={(e: number | any) => setTreatmentDuration(e)}
                                        addonBefore={t("treatment-reminder-creation.during")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="durationUnit"
                                    style={{ width: '40%' }}
                                >
                                    <Select defaultValue={props.event.duration.unit} onSelect={(e: any) => setDurationUnit(e)}>
                                        <Select.Option value="day">{t("time-units.day")}</Select.Option>
                                        <Select.Option value="week">{t("time-units.week")}</Select.Option>
                                        <Select.Option value="month">{t("time-units.month")}</Select.Option>
                                        <Select.Option value="year">{t("time-units.year")}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                        <Typography.Text style={{ marginBottom: '20px' }}>
                            {t("treatment-renew.next-take")} : <b>{nextTreatmentDate}</b>
                        </Typography.Text>
                    </>
                )}
            </Modal>
        </>
    )
}

export default TreatmentValidationModal;
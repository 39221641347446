import { Button,  Divider, Flex, Form, Input,  List } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import './profile.css'
import AddVets from './AddVets';
import InfoContainer from '../infoContainer/InfoContainer';
import { deleteVet, getVetFromUser, updateVet } from '../../request/vet';
import { GoTrash } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

type Vet = {
    vet_name: string;
    vet_clinic: string;
    vet_email: string;
    vet_phone_number: string;
    _id: string
}

function VetsDisplay(props: any) {
    const {t} = useTranslation();

    const [openModifyVet, setOpenModifyVet] = useState<boolean[]>([])
    const [vets, setVets] = useState<Vet[]>();

    const [vetClinic, setVetClinic] = useState<string>("");
    const [vetName, setVetName] = useState<string>("");
    const [vetEmail, setVetEmail] = useState<string>("");
    const [vetPhoneNumber, setVetPhoneNumber] = useState<string>("");

    const handleModifyOpen = (key: number, vet: Vet) => {
        setOpenModifyVet(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = !openModifyVet[key];
            return newState;
        });
        setVetClinic(vet.vet_clinic);
        setVetName(vet.vet_name);
        setVetEmail(vet.vet_email);
        setVetPhoneNumber(vet.vet_phone_number);
    }

    const handleSubmit = async (id: string) => {
        try {
            await updateVet(vetName, vetClinic, vetEmail, vetPhoneNumber, id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        getVets();
    }

    const handleDeleteVet = async (id: string) => {
        try {
            await deleteVet(id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        getVets();
    }

    const getVets = async () => {
        setVets(await getVetFromUser());
    }

    useEffect(() => {
        getVets()
    })

    return (
        <div style={{maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Divider className='profil-divider'>{vets && vets?.length > 1 ? t("vet-profile.title") : t("vet-profile.title").slice(0, -1)}</Divider>
            {vets && vets.map((vet: Vet, key: number) => (
                <InfoContainer key={key}>
                    <List
                        bordered
                        style={{ width: '100%' }}
                        size="large"
                        dataSource={
                            openModifyVet[key] ? [
                                <Input value={vetName} onChange={(e) => setVetName(e.target.value)}/>,
                                <Input value={vetClinic} onChange={(e) => setVetClinic(e.target.value)}/>,
                                <Input value={vetEmail} onChange={(e) => setVetEmail(e.target.value)}/>,
                                <Input value={vetPhoneNumber} onChange={(e) => setVetPhoneNumber(e.target.value)}/>,
                            ] : [
                                <span>{t("vet-profile.name")}: {vet.vet_name}</span>,
                                <span>{t("vet-profile.clinic")}: {vet.vet_clinic}</span>,
                                <span>{t("vet-profile.email")}: {vet.vet_email}</span>,
                                <span>{t("vet-profile.phone-number")}: {vet.vet_phone_number}</span>,
                            ]
                        }
                        renderItem={(item) => <List.Item style={{ padding: '10px' }}>{item}</List.Item>}
                    />
                    <Flex justify={openModifyVet[key] ? "space-between" : "flex-end"} style={{ marginTop: '5px' }}>
                        {openModifyVet[key] &&
                            <Button
                                style={{ overflow: 'hidden' }}
                                onClick={() => handleDeleteVet(vet._id)}
                                icon={<GoTrash />}
                            />
                        }
                        <Flex gap={5}>
                            <Button
                                style={{ overflow: 'hidden' }}
                                onClick={() => handleModifyOpen(key, vet)}
                                icon={<EditOutlined />}
                            />
                            {openModifyVet[key] &&
                                <Button
                                    type='primary'
                                    disabled={(vetName === "") && (vetClinic == "")}
                                    onClick={() => {
                                        handleModifyOpen(key, vet);
                                        handleSubmit(vet._id)
                                    }}
                                >
                                    {t("form.save")}
                                </Button>
                            }
                        </Flex>
                    </Flex>
                </InfoContainer>
            ))}
            <Flex style={{ marginBottom: '20px', marginTop: '20px' }}>
                <AddVets renew={getVets}/>
            </Flex>
        </div>
    );
}

export default VetsDisplay;

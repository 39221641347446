import React, { PropsWithChildren } from 'react';
import { Card } from "antd";

import './infoContainer.css'
import { customTheme } from '../../config/theme';


type Props = {
  cardTitle?: string | JSX.Element,
  extra?: string | JSX.Element,
}

function InfoContainer(props: PropsWithChildren<Props>) {
  return (
    <div className='container'>
      <Card
        title={props.cardTitle}
        id='info-container'
        extra={props.extra ? (
          props.extra
        ) : (
          null
        )}
        style={{ height: 'auto', width: '600px', maxWidth: '600px', minWidth: '100px', background: customTheme.colorContainer, borderColor: customTheme.colorContainerBorder }}
      >
        {props.children}
      </Card>
    </div>
  );
}

export default InfoContainer;

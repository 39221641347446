import React, { useState } from 'react';
import { Button, Modal, Form, Input, Switch, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { MdArrowRightAlt } from "react-icons/md";

import { createWeight } from '../../request/weight';
import { customTheme } from '../../config/theme';
import { dateFormat } from '../../config/format';
import CustomDatePicker from '../customPicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
};

type WeightState = {
  setWeight: React.Dispatch<React.SetStateAction<number | null>>,
  t: TFunction<"translation", undefined>
}

const WeightEntry = ({ setWeight, t }: WeightState) => {
  return (
    <Form.Item
      label={t("weight-section.weight")}
      name="weight"
      rules={[
        {
          required: true,
          message: ''
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if ((!value && value != 0) || (getFieldValue('weight').replace(',', '.') >= 0.1 && getFieldValue('weight').replace(',', '.') <= 150)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(t("weight-section.input-weight-between")));
          },
        })
      ]}
      style={{ position:'relative', top: '100%' }}
    >
      <Input
        placeholder={t("weight-section.weight-placeholder")}
        style={{ width: '100%' }}
        onChange={(e: any) => setWeight(parseFloat((e.target.value).replace(',', '.')))}
        addonAfter="kg"
      />
    </Form.Item>
  );
}

const WeightCalcul = ({ setWeight, t }: WeightState) => {
  const [userWeight, setUserWeight] = useState<number | null>(null);
  const [userPetWeight, setUserPetWeight] = useState<number | null>(null);

  return (
    <>
      <Form.Item
        label=""
        name="user_weight"
        rules={[
          {
            required: true, message: ''
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if ((!value && value != 0) || getFieldValue('user_weight').replace(',', '.') >= 0.1) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("weight-section.weight-above")));
            },
          })
        ]}
        style={{ position:'relative', top: '100%' }}
      >
        <Input
          placeholder={t("weight-section.weight-without-pet")}
          style={{ width: '100%' }}
          onChange={(e: any) => {
            setUserWeight(parseFloat((e.target.value).replace(',', '.')))
            setWeight((userPetWeight || 0) - (userWeight || 0))
          }}
          addonAfter="kg"
        />
      </Form.Item>
      <Form.Item
        label=""
        name="user_pet_weight"
        rules={[
          {
            required: true, message: ''
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if ((!value && value != 0) || getFieldValue('user_pet_weight').replace(',', '.') >= 0.1) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("weight-section.weight-above")));
            },
          })
        ]}
        style={{ position:'relative', top: '100%' }}
      >
        <Input
          placeholder={t("weight-section.weight-with-pet")}
          style={{ width: '100%' }}
          onChange={(e: any) => {
            setUserPetWeight(parseFloat((e.target.value).replace(',', '.')))
            setWeight((parseFloat((e.target.value).replace(',', '.')) || 0) - (userWeight || 0))
          }}
          addonAfter="kg"
        />
      </Form.Item>
      <Form.Item
        label={t("weight-section.weight")}
        name="weight"
        style={{ position:'relative', top: '100%' }}
      >
        <>
          <Flex justify='center' style={{ flexDirection: 'column', height: '30px' }}>
            <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
              <MdArrowRightAlt style={{ width: '25px', fontSize: '40px', color: customTheme.colorPrimary }}/>
              <Typography.Text style={{ fontSize: '19px' }}>
                {userPetWeight && userWeight && (userPetWeight - userWeight > 0)
                  ? `${(userPetWeight - userWeight).toFixed(2)} kg`
                  : "0 kg"
                }
              </Typography.Text>
            </Flex>
            </Flex>
            {userPetWeight && userWeight && (userPetWeight - userWeight > 0)
              && ((userPetWeight - userWeight < 0.1) || (userPetWeight - userWeight > 150))
                ? <Typography.Text
                    style={{ fontSize: '14px' , color: 'rgb(255, 0, 0, 0.8)', fontWeight: 'lighter', marginTop: '-10px' }}
                  >
                    {t("weight-section.calcul-weight-between")}
                  </Typography.Text>
                : null
            }
        </>
      </Form.Item>
    </>
  );
}

function AddWeight({onSubmit}: any) {
  const {t} = useTranslation();

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const [weight, setWeight] = useState<number | null>(null);
  const [date, setDate] = useState(dayjs(dayjs()));

  const [switchState, setSwitchState] = useState(true);

  const handleSubmit = async () => {
    if (weight !== null) {
      const newDate = dayjs(date);

      await createWeight(weight, newDate.format(`${dateFormat} HH:mm`));
      form.setFieldsValue({
        weight: null,
        date: dayjs(dayjs())
      });
      setDate(dayjs(dayjs()));
      setWeight(null);
      onSubmit();
    }
  }

  const disableFutureDates = (current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf('day');
  }

  const resetForm = () => {
    form.setFieldsValue({
      weight: null,
      user_weight: null,
      user_pet_weight: null,
      date: dayjs(dayjs())
    });
  }

  const handleCancel = () => {
    setOpen(false);
    resetForm();
    setSwitchState(true);
  }

  const handleOk = () => {
    form.submit();
    if (weight !== null && weight > 0.1 && weight < 150) {
      setOpen(false);
    }
  }

  return (
    <>
      <Button type='default' onClick={() => setOpen(!open)}>
        {t("weight-section.add-weight")}
      </Button>
      <Modal
        open={open}
        height={50}
        title={t("weight-section.add-weight-title")}
        okText="Ajouter"
        cancelText="Annuler"
        onCancel={handleCancel}
        onOk={handleOk}
        destroyOnClose
        footer={
          <Flex justify="space-between" style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
            <Switch
              checkedChildren={t("weight-section.input")}
              unCheckedChildren={t("weight-section.calcul")}
              defaultChecked
              onChange={(state: boolean) => {
                setSwitchState(state)
                resetForm();
              }}
            />
            <Flex gap={8}>
              <Button onClick={handleCancel}>
                {t("form.cancel")}
              </Button>
              <Button type='primary' onClick={handleOk}>
                {t("form.add")}
              </Button>
            </Flex>
          </Flex>
        }
      >
        <Form
          {...formItemLayout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          {switchState
            ? <WeightEntry setWeight={setWeight} t={t}/>
            : <WeightCalcul setWeight={setWeight} t={t}/>
          }
          <Form.Item
            label={t("appointment-creation.date")}
            name="date"
            style={{ position:'relative', top: '100%' }}
          >
            <CustomDatePicker
              disabledDate={disableFutureDates}
              defaultValue={dayjs(dayjs())}
              style={{ width: '100%' }}
              placeholder={t("appointment-creation.date-placeholder")}
              onChange={(e: any) => setDate(e as any)}
              format={`${dateFormat}`}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddWeight;

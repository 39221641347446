export const customTheme = {
  textColor: 'black',
  colorBackground: '#E6F7FF',
  colorPrimary: '#007BFF',
  colorPrimaryHover: 'rgba(0, 123, 255, 0.3)',
  colorSecondary: '#FFFFFF',
  colorContainer: '#FFFFFF',
  colorContainerBorder: '#F5F5F5',
  blueBackgroundTheme: '#007BFF',
  lighterBludBackgroundTheme: "#0EDFE0",
  gradientBackground: "linear-gradient(to right, var(--blueBackgroundTheme) 33%, var(--lighterBludBackgroundTheme) 100%)",
  // gradientBackground: "linear-gradient(145deg, var(--blueBackgroundTheme) 33%, var(--lighterBludBackgroundTheme) 100%)",
};

// export const customTheme = {
//     textColor: 'white',
//     colorBackground: '#E6F7FF',
//     colorPrimary: 'rgb(243, 97, 96)',
//     colorPrimaryHover: 'rgba(215, 85, 84, 0.8)',
//     colorSecondary: 'rgb(32, 56, 100)',
//     colorContainer: 'rgba(197, 194, 194, 0.2)',
//     colorContainerBorder: 'rgba(197, 194, 194, 0.2)',
// };

export const applyTheme = (theme: Record<string, string>) => {
  const root = document.documentElement;
  Object.keys(theme).forEach(key => {
    root.style.setProperty(`--${key}`, theme[key]);
  });
};

// Apply the theme
applyTheme(customTheme);
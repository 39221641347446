import axios from "axios";

async function sendFeedback(feedback: string, fileList: any[]) {
    try {
        const files = fileList.map((file) => {
            return {
                path: file.urlBase64,
            }
        });
        const data = {
            feedback: feedback,
            file: files
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/feedback`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

export { sendFeedback };
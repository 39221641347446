import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Input, InputNumber, Segmented, Select, Typography } from "antd";
import dayjs from 'dayjs';
import { ArrowLeftOutlined, ArrowRightOutlined, ConsoleSqlOutlined, LeftOutlined } from "@ant-design/icons";
import { GoTrash } from "react-icons/go";

import '../weight.css'
import { LastWeight } from "../DisplayWeight";
import AddWeight from "../AddWeight";
import { getUserSelectedPetAvatar } from "../../../request/user/pet";
import { deleteWeight, updateWeight } from "../../../request/weight";
import WeightChart from "../WeightChart";
import { dateFormat } from "../../../config/format";
import { customTheme } from "../../../config/theme";
import PetAvatar, { assignPetAvatar } from "../../pets/PetAvatar";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { SHORTEN_MONTH } from "../../../config/define";
import { TFunction } from "i18next";

type WeightDetails = {
    weight: number;
    created_at: string;
    pet_id: string;
};

function formatDate(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [date, time] = dateToFormat.split(' ')
        const [day, month, year] = date.split('-');

        return dayjs(`${year}-${month}-${day} ${time}`)
    }
    return dateToFormat;
}

export function isDateToday(created_at: string) {
    const [date] = created_at.split(" ");
    const newDate = new Date().toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });

    if (date.replaceAll('-', '/') === newDate) {
        return true;
    }
    return false;
}

function WeightDrawer(props: any) {
    const {t} = useTranslation();

    const [open, setOpen] = useState<boolean>(true);
    const [openModifyWeight, setOpenModifyWeight] = useState<boolean>(false);
    const [openWeight, setOpenWeight] = useState<boolean[]>([]);

    const [petAvatar, setPetAvatar] = useState<{species: string, avatar: string, id: string}>();
    const [weight, setWeight] = useState<number | string>(0);
    const [date, setDate] = useState<dayjs.Dayjs>(dayjs());

    const [idealWeightRange, setIdealWeightRange] = useState({min: 0, max: 0})

    const [data, setData] = useState<any>(props.weight);

    const handleSubmit = async (id: number) => {
        try {
            const formattedDate = date.format(`${dateFormat} HH:mm`)   ;

            await updateWeight(Number(weight), formattedDate.replaceAll('/', '-'), id);
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status);
            }
        }
        if (props.renew) {
            props.renew();
        }
    }

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    }

    const getPetAvatar = async () => {
        const response = await assignPetAvatar(await getUserSelectedPetAvatar(), "id", "avatar", false);

        setPetAvatar(response);
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    const handleWeightClick = (key: number, _weight: any) => {
        setOpenWeight(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = !openWeight[key];
            return newState;
        })
        setOpenModifyWeight(false)
    }

    const removeWeight = async (id: string) => {
        try {
            await deleteWeight(id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.renew) {
            props.renew()
        }
    }

    useEffect(() => {
        getPetAvatar()
        setData(props.weight);
    }, [props.weight]);

    return (
        <Drawer
            title={<>{LastWeight(props.weight, t, idealWeightRange)}</>}
            extra={
                <AddWeight onSubmit={props.renew}/>
            }
            onClose={() => handleClose(false)}
            open={open}
            width={650}
            closeIcon={<LeftOutlined />}
        >
            <ChoseOptionWeight weight={props.weight} setData={setData} t={t}/>
            <div style={{ backgroundColor: 'rgba(197, 194, 194, 0.4)', borderRadius: '20px', padding: 10 }}>
                <WeightChart weight={data} setWeightrange={setIdealWeightRange}/>
            </div>
            <Divider/>
            <Flex gap={'13px'} style={{ flexDirection: 'column' }}>
                {data && data.toReversed().map((_weight: any, key: any) => (
                    <Card
                        key={key}
                        {...!openWeight[key] ? { onClick: () => handleWeightClick(key, _weight) } : null}
                        style={{ height: 'auto', minWidth: '100px', ...openWeight[key] === true && {boxShadow: '0px 4px 10px rgb(0, 0, 0, 0.2)' } }}
                        hoverable={!openWeight[key]}
                    >
                        <Flex
                            style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                            onClick={() => handleWeightClick(key, _weight)}
                        >
                            <PetAvatar species={petAvatar?.species || ""} url={petAvatar?.avatar || ""} iconSize={{width: "30px", height: "30px"}}/>
                            {' '}
                            <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                            <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                {(_weight.created_at).split(" ")[0]}
                                {isDateToday(_weight.created_at) ? ` (AUJOURD'HUI)` : '' } - {_weight.weight}kg
                            </Typography.Text>
                        </Flex>
                        <Flex
                            gap={'10px'}
                            style={{
                                flexDirection: 'column',
                                maxHeight: openWeight[key] ? '300px' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.5s ease'
                            }}
                        >
                            {openWeight[key] && openModifyWeight &&
                                <Flex gap={'5px'} style={{ flexDirection: 'row', marginTop: '5px' }} className="drawer-weight-modify">
                                    <Input
                                        value={weight}
                                        placeholder={t("weight-section.weight-placeholder")}
                                        style={{ width: '100%', zIndex: 999}}
                                        onChange={(e) => setWeight((e.target.value).replace(',', '.'))}
                                        addonAfter="kg"
                                    />
                                    <CustomDatePicker
                                        disabledDate={disableFutureDates}
                                        defaultValue={date}
                                        style={{ width: '100%' }}
                                        placeholder={t("appointment-creation.date-placeholder")}
                                        format={`${dateFormat}`}
                                        onChange={(e: any) => setDate(e as any)}
                                    />
                                </Flex>
                            }
                            <Flex justify="space-between">
                                <Flex justify='flex-start' gap={'8px'} style={{ flexDirection: 'row' }}>
                                    <Button
                                        onClick={() => {
                                            handleWeightClick(key, _weight)
                                            removeWeight(_weight._id)
                                        }}
                                        type="default"
                                        style={{ border: 'none', outline: 'none', boxShadow: 'none' }}
                                        icon={<GoTrash/>}
                                    />
                                </Flex>
                                <Flex justify='flex-end' gap={'8px'} style={{ flexDirection: 'row' }}>
                                    <Button
                                        type="primary"
                                        style={{ width: '82px' }}
                                        onClick={() => {
                                            setOpenModifyWeight(!openModifyWeight)
                                            setWeight(_weight.weight);
                                            setDate(formatDate(_weight.created_at));
                                        }}
                                    >
                                        {openModifyWeight
                                            ? t("form.cancel")
                                            : t("pet-profile.modify")}
                                    </Button>
                                    {openModifyWeight &&
                                        <Button
                                            style={{ width: '96px' }}
                                            disabled={(weight === null ? true : (Number(weight) < 0.1 || Number(weight) > 150)) || (date === null)}
                                            onClick={() => {
                                                handleSubmit(_weight._id)
                                                setOpenModifyWeight(false);
                                            }}
                                        >
                                            {t("form.save")}
                                        </Button>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Card>
                ))}
            </Flex>
        </Drawer>
    );
}

function groupByMonthYear(
    weights: any,
    month: number,
    year: string,
    chosenOption: string,
    t: TFunction<'translae', undefined>
) {
    let grouped: any = [];

    if (chosenOption === t("time-option.all")) {
        return weights
    }
    if (chosenOption === t("time-option.year")) {
        for (let i = 0; i < weights.length; i++) {
            if (year === weights[i].created_at.split(' ')[0].split('-')[2]) {
                grouped.push(weights[i]);
            }
        }
    }
    if (chosenOption === t("time-option.month")) {
        for (let i = 0; i < weights.length; i++) {
            const date = weights[i].created_at.split(' ')[0];

            if (year === date.split('-')[2] && month + 1 === Number(date.split('-')[1])) {
                grouped.push(weights[i]);
            }
        }
    }
    return grouped;
}

function ChoseOptionWeight(props: any) {
    const [chosenOption, setChosenOption] = useState<string>(props.t("time-option.all"));
    const options: string[] = [props.t("time-option.all"), props.t("time-option.year"), props.t("time-option.month")];

    const firstLastYear = (weights: any) => {
        if (weights.length == 0) {
            return [];
        }
        let years: {value: string, label: string}[] = [];
        const firstDate: string = weights[0].created_at.split(' ')[0];
        const lastDate: string = weights[weights.length - 1].created_at.split(' ')[0];
        const firstYear: number = Number(firstDate.split('-')[2]);
        const lastYear: number = Number(lastDate.split('-')[2]);

        for (let i = firstYear; i <= lastYear; i++) {
            years.push({value: String(i), label: String(i)});
        }
        return years.reverse();
    }

    const years: {value: string, label: string}[] = firstLastYear(props.weight);

    const [counterYear, setCounterYear] = useState<string>(years[0].label)
    const [counterMonth, setCounterMonth] = useState<number>(dayjs().month())

    return (
        <>
            <Segmented
                options={options}
                style={{ marginBottom: '5px' }}
                block
                defaultValue={props.t("time-option.all")}
                onChange={(e) => {
                    props.setData(groupByMonthYear(props.weight, counterMonth, counterYear, e, props.t))
                    setChosenOption(e);
                }}
            />
            {chosenOption !== props.t("time-option.all") &&
                <Flex justify='flex-end' style={{ marginBottom: '5px', alignItems: 'center' }}>
                    {chosenOption === props.t("time-option.month") &&
                        <>
                            <Button
                                icon={<ArrowLeftOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterMonth((counterMonth <= 0 ? 11 : counterMonth - 1))
                                    props.setData(groupByMonthYear(props.weight, (counterMonth <= 0 ? 11 : counterMonth - 1), counterYear, chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                            <Flex justify='center' style={{ width: '30px' }}>
                                <Typography.Text>
                                    {SHORTEN_MONTH(props.t)[counterMonth]}
                                </Typography.Text>
                            </Flex>
                            <Button
                                icon={<ArrowRightOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterMonth((counterMonth >= 11 ? 0 : counterMonth + 1))
                                    props.setData(groupByMonthYear(props.weight, (counterMonth >= 11 ? 0 : counterMonth + 1), counterYear, chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                        </>
                    }
                    <Select options={years} style={{ width: 80 }} defaultValue={counterYear}
                        onChange={(e) => {
                            setCounterYear(e)
                            props.setData(groupByMonthYear(props.weight, counterMonth, e, chosenOption, props.t))
                        }}
                    />
                </Flex>
            }
        </>
    );
}

export { WeightDrawer }
import React from 'react';

import CalendarDisplay from '../../component/calendar/CalendarDisplay';

function Calendar() {
  return (
    <CalendarDisplay />
  );
}

export default Calendar;

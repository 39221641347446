import { Button, Card, Divider, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getSpecificUser } from '../../request/user/user';
import ProfileDisplay from '../../component/profile/ProfileDisplay';

type UserDetails = {
  firstname: string,
  username: string,
  email: string
}

function Profile() {
  const [user, setUser] = useState<UserDetails>();


  const getUser = async () => {
    const result = await getSpecificUser();

    setUser(result);
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
      <div className="Profile">
        <ProfileDisplay user={user}/>
      </div>
  );
}

export default Profile;

import React, { useEffect, useState } from "react"
import { Button, Divider, Drawer, Flex, Form, Input, Typography } from "antd";
import dayjs from 'dayjs';
import { LeftOutlined } from "@ant-design/icons";

import './calendarDrawer.css'
import { updateEvent, deleteEvent } from "../../../request/events";
import SelectPet from "../../navBar/SelectPet";
import DeleteConfirm from "../../confirm/DeleteConfirm";
import { dateFormat } from "../../../config/format";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import CustomTimePicker from "../../customPicker/CustomTimePicker";
import { useTranslation } from "react-i18next";

function formatDateTime(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [date, time] = dateToFormat.split(' ');
        const [day, month, year] = date.split('-');
        const formattedDateTime = `${year}-${month}-${day} ${time}`;

        return dayjs(formattedDateTime)
    }
    return dateToFormat;
}

function EventDrawerAppointment(props: any) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const [modify, setModify] = useState(false);
    let event = props.event;

    const [pet, setPet] = useState<{pet_id: string, species: string, name: string}>(event.pet);
    const [motive, setMotive] = useState(event.motive);
    const [notes, setNotes] = useState(event.notes);

    const [date, setDate] = useState(formatDateTime(event.date));
    const [time, setTime] = useState(formatDateTime(event.date));

    const changeEvent = () => {
        const formattedDate = dayjs(date).format(`${dateFormat}`);
        const formattedTime = dayjs(time).format('HH:mm');

        event.pet = pet;
        event.motive = motive;
        event.notes = notes;
        event.date = `${formattedDate} ${formattedTime}`;
    }

    const handleSubmit = async () => {
        try {
            const formattedDate = dayjs(date).format(`${dateFormat}`);
            const formattedTime = dayjs(time).format('HH:mm');

            const formattedDateTime = `${formattedDate} ${formattedTime}`;
            await updateEvent({ pet_id: pet.pet_id }, formattedDateTime, "", motive, notes, event._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setModify(false);
        if (props.renew) {
            props.renew();
        }
        changeEvent();
    }

    const handleSelectPet = (pet: {pet_id: string, species: string, name: string}) => {
        setPet({pet_id: pet.pet_id, species: pet.species, name: pet.name});
    }

    const removeEvent = async () => {
        try {
            await deleteEvent(event._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        handleClose(true);
    }

    const handleModify = async () => {
        if (modify) {
            const formattedDateTime = formatDateTime(event.date);

            setPet(event.pet);
            setDate(formattedDateTime);
            setTime(formattedDateTime);
            setMotive(event.motive);
            setNotes(event.notes);
        }
        setModify(!modify);
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    const getTranslatedAppointmentType = (type: string) => {
        const appointmentTranslateSearch = `appointment-creation.appointment-type.${type}`

        if (appointmentTranslateSearch != t(appointmentTranslateSearch)) {
          return t(appointmentTranslateSearch);
        }
        return type;
    }

    useEffect(() => {
        setOpen(true)
    }, []);

    return (
        <>
            <Drawer
                title={`${(event.date).split(' ')[0]} - ${getTranslatedAppointmentType(event.name)}`}
                extra={
                    <Button onClick={() => handleModify()}>
                        {!modify ? t("pet-profile.modify") : t("form.cancel")}
                    </Button>
                }
                footer={<DeleteConfirm delete={removeEvent}>{t("form.remove")}</DeleteConfirm>}
                onClose={() => handleClose(false)}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
            >
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.pet")} : </Typography.Text>
                    <SelectPet
                        pet={pet.name}
                        disabled={!modify}
                        changeCookies={false}
                        onChange={handleSelectPet}
                        width='100%'
                    />
                </Flex>
                <Flex style={{ flexDirection: 'row', width: '100%' }}>
                    <Flex style={{ flexDirection: 'column', marginBottom: '10px', width: '49%' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Date :</Typography.Text>
                        <CustomDatePicker
                            value={date}
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                            onChange={(e: any) => setDate(e as any)}
                            format={`${dateFormat}`}
                            disabled={!modify}
                        />
                    </Flex>
                    <div style={{ width: '2%' }}/>
                    <Flex style={{ flexDirection: 'column', marginBottom: '10px', width: '49%' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.time")} :</Typography.Text>
                        <CustomTimePicker
                            value={time}
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                            onChange={(e: any) => setTime(e as any)}
                            format={'HH:mm'}
                            disabled={!modify}
                        />
                    </Flex>
                </Flex>
                <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.reason")} :</Typography.Text>
                <Input.TextArea
                    rows={4}
                    value={motive}
                    style={{ backgroundColor: 'white', color: 'black', cursor: 'default', marginBottom: '10px' }}
                    autoSize={{ minRows: 3 }}
                    disabled={!modify}
                    onChange={(e) => setMotive(e.target.value)}
                />
                <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-drawer.notes")} :</Typography.Text>
                <Input.TextArea
                    rows={4}
                    value={notes}
                    style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                    autoSize={{ minRows: 3 }}
                    disabled={!modify}
                    onChange={(e) => setNotes(e.target.value)}
                />
                {modify &&
                    <Button
                        type="primary"
                        style={{ marginTop: '10px' }}
                        onClick={handleSubmit}
                    >
                        {t("form.save")}
                    </Button>
                }
            </Drawer>
        </>
    );
}

export { EventDrawerAppointment }
import { TFunction } from "i18next"

export const TypeDataTreatment = (t: TFunction<"translate", undefined>) => {
    return (
        [
            {
                label: t("treatment-reminder-creation.treatment-reminder-type.vaccine"),
                value: "vaccine",
            },
            {
                label: t("treatment-reminder-creation.treatment-reminder-type.deworming"),
                value: "deworming",
            },
            {
                label: t("treatment-reminder-creation.treatment-reminder-type.anti-parasitic"),
                value: "anti-parasitic",
            },
            {
                label: t("treatment-reminder-creation.treatment-reminder-type.medicines"),
                value: "medicines",
            },
            {
                label: t("treatment-reminder-creation.treatment-reminder-type.other"),
                value: "other",
            }
        ]
    );
}

export const TypeDataAppointment = (t: TFunction<"translate", undefined>) => {
    return (
        [
            {
                label: t("appointment-creation.appointment-type.veterinarian"),
                value: "veterinarian",
            },
            {
                label: t("appointment-creation.appointment-type.groomer"),
                value: "groomer",
            },
            {
                label: t("appointment-creation.appointment-type.osteopath"),
                value: "osteopath",
            },
            {
                label: t("appointment-creation.appointment-type.other"),
                value: "other",
            }
        ]
    );
}
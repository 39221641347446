import React, { useState } from "react"
import { Button, Drawer, Flex } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { GiMedicinePills } from "react-icons/gi";
import { useTranslation } from "react-i18next";

import CalendarModal from "../../calendar/modal/CalendarModal";
import { ReactComponent as PetProtection } from "../../../assets/icons/healthRecord/PetProtection.svg";

function ReminderDrawer(props: any) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);
    const [isModalVisible, setModalVisibility] = useState(false);

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const handleModalSubmit = async (submit: boolean) => {
        setModalVisibility(false);
    }

    return (
        <>
            {isModalVisible && <CalendarModal selectedEvent={"treatment-reminder"} onSubmit={handleModalSubmit}/>}
            <Drawer
                className="health-record-drawer"
                onClose={() => handleClose()}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                title={
                    <>
                        <Flex style={{ width: '30px'}}>
                            <PetProtection/>
                        </Flex>
                        {t("health-records-page.prevention-vaccines")}
                    </>
                }
                footer={
                    <Flex justify="end">
                        <Button type="primary" onClick={() => setModalVisibility(true)}>
                            {t("treatment-reminder-creation.add-treatment-reminder")}
                        </Button>
                    </Flex>
                }
            >
            </Drawer>
        </>

    );
}

export { ReminderDrawer }

import React, { PropsWithChildren, useState } from 'react';

import { Button, Flex, Modal, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

type Props = {
    delete: () => void,
}

function DeleteConfirm(props: PropsWithChildren<Props>) {
    const [open, setOpen] = useState<boolean>(false);

    const ModalTitle = () => {
        return (
            <Flex style={{ flexDirection: 'row' }} gap={10}>
                <ExclamationCircleFilled style={{ color: 'rgb(253, 179, 0)', fontSize: '22px' }}/>
                <Typography.Text style={{ fontSize: '16px' }}>Etes vous sur de vouloir supprimer cela ?</Typography.Text>
            </Flex>
        );
    }

    return (
    <>
        <Button
            onClick={() => setOpen(true)}
        >
            {props.children}
        </Button>
        <Modal
            closeIcon={null}
            open={open}
            height={138}
            width={416}
            title={<ModalTitle/>}
            okText="Supprimer"
            okType='danger'
            cancelText="Annuler"
            onCancel={() => setOpen(false)}
            onOk={() => props.delete()}
            destroyOnClose
        >
            <br/>
        </Modal>
    </>
  );
}

export default DeleteConfirm;

import axios from 'axios';
import { Image } from '../component/imageUploader/ImageUploader';

type PetParam = {
  name: string,
  species: string,
  gender: string,
  breed: string,
  neutered: boolean,
  date: string,
  petIdNumber: number,
  passportNumber: string,
  vet: {vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string} | {},
  medicalHistory: string
  pet_id?: string
  image?: Image
}

const getPetsFromUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pet`,
        {
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [];
    }
}

const createPet = async (param: PetParam) => {
  try {
    const {name, species, gender, breed, neutered, date, petIdNumber, passportNumber, vet, medicalHistory, image}: PetParam = param;

    const data = JSON.stringify({
      name,
      species,
      gender,
      breed,
      neutered,
      birth_date: date,
      pet_id_number: petIdNumber,
      passport_number: passportNumber,
      vet: vet,
      medical_history: medicalHistory,
      image
    }, null, 1);
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pet`,
        data,
        {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        },
    );
    return response.data;
  } catch (error: any) {
      throw error;
  }
}

const deletePet = async (id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/pet/${id}`,
      {
        withCredentials: true,
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const updatePet = async (param: PetParam) => {
  try {
    const {image, name, species, gender, breed, neutered, date, petIdNumber, passportNumber, vet, medicalHistory, pet_id} = param;

    const data = JSON.stringify({
      image,
      name,
      species,
      gender,
      breed,
      neutered,
      birthDate: date,
      petIdNumber,
      passportNumber,
      vet: vet,
      medicalHistory,
      pet_id
    }, null, 1);
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/pet`,
        data,
        {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        },
    );
    return response.data;
  } catch (error: any) {
      throw error;
  }
}

export {
  getPetsFromUser,
  deletePet,
  createPet,
  updatePet
}
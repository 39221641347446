import React from 'react';
import { Flex } from 'antd';

import './loadingScreen.css'

function LoadingScreen() {
    return (
        <Flex
            justify="center"
            style={{ height: '100vh', width: '100vw', background: 'var(--gradientBackground)', alignItems: 'center', position: 'absolute', zIndex: 999 }}
        >
            <img
                className='dog-icon'
                src="assets/images/LoadingIcon.png"
                loading="lazy"
                alt=""
                style={{ width: '250px', maxWidth: '250px', height: '250px', maxHeight: '250px' }}
            />
        </Flex>
    );
}

export default LoadingScreen;

import React, { useEffect, useState } from 'react';
import { Button, Flex, Image, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';

import './imageUploader.css'
import InfoContainer from '../infoContainer/InfoContainer';
import { NO_IMAGE } from '../../config/define';

export interface Image {
  size: number,
  mimeType: string,
  file: any,
}

export interface ImageUploader {
  setImage: React.Dispatch<React.SetStateAction<Image>>,
  defaultImage?: string,
  disabled?: boolean,
  cancel?: boolean
}

export const defaultImage = {
  size: 0,
  mimeType: "",
  file: "" as any,
};

function ImageUploader(setter: ImageUploader) {
  const [thumbUrl, setThumbUrl] = useState(NO_IMAGE);

  const [reRender, setReRender] = useState(new Date());

  const handleImageUpload = (file: any, fileList: any) => {
    const fileBuffer = file;
    const reader = new FileReader();

    if (fileBuffer) {
      reader.readAsDataURL(fileBuffer);
    }
    setTimeout(() => {
      setReRender(new Date());
      setter.setImage({
        size: file.size || 0,
        mimeType: file.type || "image/png",
        file: file,
      });
      setThumbUrl(reader.result as string);
    }, 150);
  }

  useEffect(() => {
    setThumbUrl(setter.defaultImage || NO_IMAGE);
  }, [setter.cancel])

  useEffect(() => {
    if (thumbUrl == NO_IMAGE) {
      setThumbUrl(setter.defaultImage || NO_IMAGE);
    }
  }, [reRender])

  return (
    <Flex>
        <Image
            className='image-uploader-preview'
            style={{ borderRadius: '150px', width: '102px', height: '102px', position: 'absolute', zIndex: 0 }}
            preview={false}
            src={thumbUrl}
        />
        <Flex justify='center' style={{ zIndex: 1, position: 'relative' }}>
            <ImgCrop rotationSlider cropShape='round' showGrid modalTitle={`Modifier l'image`}>
                <Upload
                    style={{ width: '100px', height: '100px', position: 'absolute' }}
                    listType="picture-circle"
                    accept='.png,.jpeg'
                    beforeUpload={(file, fileList) => {
                      handleImageUpload(file, fileList)
                      return false;
                    }}
                    disabled={setter.disabled || false}
                >
                    {' '}
                </Upload>
            </ImgCrop>
        </Flex>
    </Flex>
  );
};

export default ImageUploader;
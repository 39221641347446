export const DogBreed = [
    {
        label: 'Affenpinscher',
        value: 'Affenpinscher',
    },
    {
        label: 'Afghan Hound',
        value: 'Afghan Hound',
    },
    {
        label: 'Airedale Terrier',
        value: 'Airedale Terrier',
    },
    {
        label: 'Akita Inu',
        value: 'Akita Inu',
    },
    {
        label: 'Alaskan Malamute',
        value: 'Alaskan Malamute',
    },
    {
        label: 'American Akita',
        value: 'American Akita',
    },
    {
        label: 'American Bulldog',
        value: 'American Bulldog',
    },
    {
        label: 'American Cocker Spaniel',
        value: 'American Cocker Spaniel',
    },
    {
        label: 'American Foxhound',
        value: 'American Foxhound',
    },
    {
        label: 'American Staffordshire Terrier',
        value: 'American Staffordshire Terrier',
    },
    {
        label: 'American Water Spaniel',
        value: 'American Water Spaniel',
    },
    {
        label: 'Anatolian Shepherd',
        value: 'Anatolian Shepherd',
    },
    {
        label: 'Appenzell Cattle Dog',
        value: 'Appenzell Cattle Dog',
    },
    {
        label: 'Ariege Pointing Dog',
        value: 'Ariege Pointing Dog',
    },
    {
        label: 'Ariegeois',
        value: 'Ariegeois',
    },
    {
        label: 'Artois Hound',
        value: 'Artois Hound',
    },
    {
        label: 'Atlas Mountain Dog (Aidi)',
        value: 'Atlas Mountain Dog (Aidi)',
    },
    {
        label: 'Australian Cattle Dog',
        value: 'Australian Cattle Dog',
    },
    {
        label: 'Australian Kelpie',
        value: 'Australian Kelpie',
    },
    {
        label: 'Australian Shepherd',
        value: 'Australian Shepherd',
    },
    {
        label: 'Australian Silky Terrier',
        value: 'Australian Silky Terrier',
    },
    {
        label: 'Australian Terrier',
        value: 'Australian Terrier',
    },
    {
        label: 'Austrian Black and Tan Hound',
        value: 'Austrian Black and Tan Hound',
    },
    {
        label: 'Austrian Pinscher',
        value: 'Austrian Pinscher',
    },
    {
        label: 'Auvergne Pointer',
        value: 'Auvergne Pointer',
    },
    {
        label: 'Azawakh',
        value: 'Azawakh',
    },
    {
        label: 'Basenji',
        value: 'Basenji',
    },
    {
        label: 'Basset Fauve de Bretagne',
        value: 'Basset Fauve de Bretagne',
    },
    {
        label: 'Basset Hound',
        value: 'Basset Hound',
    },
    {
        label: 'Bavarian Mountain Scent Hound',
        value: 'Bavarian Mountain Scent Hound',
    },
    {
        label: 'Beagle',
        value: 'Beagle',
    },
    {
        label: 'Beagle Harrier',
        value: 'Beagle Harrier',
    },
    {
        label: 'Bearded Collie',
        value: 'Bearded Collie',
    },
    {
        label: 'Bedlington Terrier',
        value: 'Bedlington Terrier',
    },
    {
        label: 'Belgian Shepherd',
        value: 'Belgian Shepherd',
    },
    {
        label: 'Bergamasco Shepherd Dog',
        value: 'Bergamasco Shepherd Dog',
    },
    {
        label: 'Bernese Mountain Dog',
        value: 'Bernese Mountain Dog',
    },
    {
        label: 'Bichon Frise',
        value: 'Bichon Frise',
    },
    {
        label: 'Billy',
        value: 'Billy',
    },
    {
        label: 'Black and Tan Coonhound',
        value: 'Black and Tan Coonhound',
    },
    {
        label: 'Bloodhound',
        value: 'Bloodhound',
    },
    {
        label: 'Blue Gascony',
        value: 'Blue Gascony',
    },
    {
        label: 'Blue Picardy Spaniel',
        value: 'Blue Picardy Spaniel',
    },
    {
        label: 'Boerboel',
        value: 'Boerboel',
    },
    {
        label: 'Bohemian Wire-haired Pointing Griffon',
        value: 'Bohemian Wire-haired Pointing Griffon',
    },
    {
        label: 'Bolognese',
        value: 'Bolognese',
    },
    {
        label: 'Border Collie',
        value: 'Border Collie',
    },
    {
        label: 'Border Terrier',
        value: 'Border Terrier',
    },
    {
        label: 'Borzoï',
        value: 'Borzoï',
    },
    {
        label: 'Bosnian Broken-haired Hound - Called Barak',
        value: 'Bosnian Broken-haired Hound - Called Barak',
    },
    {
        label: 'Boston Terrier',
        value: 'Boston Terrier',
    },
    {
        label: 'Bourbonnais Pointing Dog',
        value: 'Bourbonnais Pointing Dog',
    },
    {
        label: 'Bouvier des Ardennes',
        value: 'Bouvier des Ardennes',
    },
    {
        label: 'Bouvier des Flandres',
        value: 'Bouvier des Flandres',
    },
    {
        label: 'Boxer',
        value: 'Boxer',
    },
    {
        label: 'Bracco Italiano (Italian Pointing dog)',
        value: 'Bracco Italiano (Italian Pointing dog)',
    },
    {
        label: 'Brazilian Terrier',
        value: 'Brazilian Terrier',
    },
    {
        label: 'Briard',
        value: 'Briard',
    },
    {
        label: 'Briquet Griffon Vendeen',
        value: 'Briquet Griffon Vendeen',
    },
    {
        label: 'Brittany',
        value: 'Brittany',
    },
    {
        label: 'Broholmer',
        value: 'Broholmer',
    },
    {
        label: 'Bull Terrier',
        value: 'Bull Terrier',
    },
    {
        label: 'Bulldog',
        value: 'Bulldog',
    },
    {
        label: 'Bullmastiff',
        value: 'Bullmastiff',
    },
    {
        label: 'Burgos Pointing Dog',
        value: 'Burgos Pointing Dog',
    },
    {
        label: 'Cairn Terrier',
        value: 'Cairn Terrier',
    },
    {
        label: 'Canaan Dog',
        value: 'Canaan Dog',
    },
    {
        label: 'Canarian Warren Hound',
        value: 'Canarian Warren Hound',
    },
    {
        label: 'Cane Corso',
        value: 'Cane Corso',
    },
    {
        label: 'Castro Laboreiro Dog',
        value: 'Castro Laboreiro Dog',
    },
    {
        label: 'Catalan Sheepdog',
        value: 'Catalan Sheepdog',
    },
    {
        label: 'Caucasian Shepherd Dog',
        value: 'Caucasian Shepherd Dog',
    },
    {
        label: 'Cavalier King Charles Spaniel',
        value: 'Cavalier King Charles Spaniel',
    },
    {
        label: 'Central Asia Shepherd Dog',
        value: 'Central Asia Shepherd Dog',
    },
    {
        label: 'Cesky Terrier',
        value: 'Cesky Terrier',
    },
    {
        label: 'Chesapeake Bay Retriever',
        value: 'Chesapeake Bay Retriever',
    },
    {
        label: 'Chihuahua',
        value: 'Chihuahua',
    },
    {
        label: 'Chinese Crested Dog',
        value: 'Chinese Crested Dog',
    },
    {
        label: 'Chow Chow',
        value: 'Chow Chow',
    },
    {
        label: 'Cirneco Dell’Etna',
        value: 'Cirneco Dell’Etna',
    },
    {
        label: 'Clumber Spaniel',
        value: 'Clumber Spaniel',
    },
    {
        label: 'Coarse-haired Styrian Hound',
        value: 'Coarse-haired Styrian Hound',
    },
    {
        label: 'Cocker Spaniel',
        value: 'Cocker Spaniel',
    },
    {
        label: 'Collie Smooth',
        value: 'Collie Smooth',
    },
    {
        label: 'Coton de Tulear',
        value: 'Coton de Tulear',
    },
    {
        label: 'Croatian Shepherd Dog',
        value: 'Croatian Shepherd Dog',
    },
    {
        label: 'Curly-Coated Retriever',
        value: 'Curly-Coated Retriever',
    },
    {
        label: 'Cursinu',
        value: 'Cursinu',
    },
    {
        label: 'Czechoslovakian Wolfdog',
        value: 'Czechoslovakian Wolfdog',
    },
    {
        label: 'Dachshund',
        value: 'Dachshund',
    },
    {
        label: 'Dalmatian',
        value: 'Dalmatian',
    },
    {
        label: 'Dandie Dinmont Terrier',
        value: 'Dandie Dinmont Terrier',
    },
    {
        label: 'Deerhound',
        value: 'Deerhound',
    },
    {
        label: 'Deutsch Langhaar',
        value: 'Deutsch Langhaar',
    },
    {
        label: 'Dobermann',
        value: 'Dobermann',
    },
    {
        label: 'Dogue de Bordeaux',
        value: 'Dogue de Bordeaux',
    },
    {
        label: 'Drentsche Partridge Dog',
        value: 'Drentsche Partridge Dog',
    },
    {
        label: 'Drever',
        value: 'Drever',
    },
    {
        label: 'Dunker',
        value: 'Dunker',
    },
    {
        label: 'Dutch Schapendoes',
        value: 'Dutch Schapendoes',
    },
    {
        label: 'Dutch Shepherd',
        value: 'Dutch Shepherd',
    },
    {
        label: 'Dutch Smoushond',
        value: 'Dutch Smoushond',
    },
    {
        label: 'East Siberian Laika',
        value: 'East Siberian Laika',
    },
    {
        label: 'English Foxhound',
        value: 'English Foxhound',
    },
    {
        label: 'English Pointer',
        value: 'English Pointer',
    },
    {
        label: 'English Setter',
        value: 'English Setter',
    },
    {
        label: 'English Springer Spaniel',
        value: 'English Springer Spaniel',
    },
    {
        label: 'Entlebucher Mountain Dog',
        value: 'Entlebucher Mountain Dog',
    },
    {
        label: 'Estrela Mountain Dog',
        value: 'Estrela Mountain Dog',
    },
    {
        label: 'Eurasier',
        value: 'Eurasier',
    },
    {
        label: 'Field Spaniel',
        value: 'Field Spaniel',
    },
    {
        label: 'Finnish Lapphund',
        value: 'Finnish Lapphund',
    },
    {
        label: 'Finnish Spitz',
        value: 'Finnish Spitz',
    },
    {
        label: 'Flat-Coated Retriever',
        value: 'Flat-Coated Retriever',
    },
    {
        label: 'French Bulldog',
        value: 'French Bulldog',
    },
    {
        label: 'French Spaniel',
        value: 'French Spaniel',
    },
    {
        label: 'German Longhaired Pointer',
        value: 'German Longhaired Pointer',
    },
    {
        label: 'German Pinscher',
        value: 'German Pinscher',
    },
    {
        label: 'German Shepherd',
        value: 'German Shepherd',
    },
    {
        label: 'German Shorthaired Pointer',
        value: 'German Shorthaired Pointer',
    },
    {
        label: 'German Spaniel',
        value: 'German Spaniel',
    },
    {
        label: 'German Wirehaired Pointer',
        value: 'German Wirehaired Pointer',
    },
    {
        label: 'Giant Schnauzer',
        value: 'Giant Schnauzer',
    },
    {
        label: 'Glen of Imaal Terrier',
        value: 'Glen of Imaal Terrier',
    },
    {
        label: 'Golden Retriever',
        value: 'Golden Retriever',
    },
    {
        label: 'Gordon Setter',
        value: 'Gordon Setter',
    },
    {
        label: 'Grand Basset Griffon Vendeen',
        value: 'Grand Basset Griffon Vendeen',
    },
    {
        label: 'Great Dane',
        value: 'Great Dane',
    },
    {
        label: 'Great Swiss Mountain Dog',
        value: 'Great Swiss Mountain Dog',
    },
    {
        label: 'Greek Harehound',
        value: 'Greek Harehound',
    },
    {
        label: 'Greenland Dog',
        value: 'Greenland Dog',
    },
    {
        label: 'Greyhound',
        value: 'Greyhound',
    },
    {
        label: 'Griffon Bruxellois',
        value: 'Griffon Bruxellois',
    },
    {
        label: 'Griffon Nivernais',
        value: 'Griffon Nivernais',
    },
    {
        label: 'Hamilton Hound',
        value: 'Hamilton Hound',
    },
    {
        label: 'Hanoverian Scenthound',
        value: 'Hanoverian Scenthound',
    },
    {
        label: 'Harrier',
        value: 'Harrier',
    },
    {
        label: 'Havanese',
        value: 'Havanese',
    },
    {
        label: 'Hovawart',
        value: 'Hovawart',
    },
    {
        label: 'Hungarian Greyhound',
        value: 'Hungarian Greyhound',
    },
    {
        label: 'Hungarian Hound',
        value: 'Hungarian Hound',
    },
    {
        label: 'Hungarian Puli',
        value: 'Hungarian Puli',
    },
    {
        label: 'Hungarian Pumi',
        value: 'Hungarian Pumi',
    },
    {
        label: 'Ibizan Hound',
        value: 'Ibizan Hound',
    },
    {
        label: 'Icelandic Sheepdog',
        value: 'Icelandic Sheepdog',
    },
    {
        label: 'Irish Glen of Imaal Terrier',
        value: 'Irish Glen of Imaal Terrier',
    },
    {
        label: 'Irish Red and White Setter',
        value: 'Irish Red and White Setter',
    },
    {
        label: 'Irish Setter',
        value: 'Irish Setter',
    },
    {
        label: 'Irish Terrier',
        value: 'Irish Terrier',
    },
    {
        label: 'Irish Water Spaniel',
        value: 'Irish Water Spaniel',
    },
    {
        label: 'Irish Wolfhound',
        value: 'Irish Wolfhound',
    },
    {
        label: 'Italian Greyhound',
        value: 'Italian Greyhound',
    },
    {
        label: 'Italian Spinone',
        value: 'Italian Spinone',
    },
    {
        label: 'Jack Russell Terrier',
        value: 'Jack Russell Terrier',
    },
    {
        label: 'Japanese Chin',
        value: 'Japanese Chin',
    },
    {
        label: 'Japanese Spitz',
        value: 'Japanese Spitz',
    },
    {
        label: 'Kangal Shepherd Dog',
        value: 'Kangal Shepherd Dog',
    },
    {
        label: 'Karelian Bear Dog',
        value: 'Karelian Bear Dog',
    },
    {
        label: 'Karst Shepherd Dog',
        value: 'Karst Shepherd Dog',
    },
    {
        label: 'Keeshond',
        value: 'Keeshond',
    },
    {
        label: 'Kerry Blue Terrier',
        value: 'Kerry Blue Terrier',
    },
    {
        label: 'King Charles Spaniel',
        value: 'King Charles Spaniel',
    },
    {
        label: 'Kishu',
        value: 'Kishu',
    },
    {
        label: 'Komondor',
        value: 'Komondor',
    },
    {
        label: 'Kooikerhondje',
        value: 'Kooikerhondje',
    },
    {
        label: 'Korean Jindo',
        value: 'Korean Jindo',
    },
    {
        label: 'Kuvasz',
        value: 'Kuvasz',
    },
    {
        label: 'Labrador Retriever',
        value: 'Labrador Retriever',
    },
    {
        label: 'Lagotto Romagnolo',
        value: 'Lagotto Romagnolo',
    },
    {
        label: 'Lakeland Terrier',
        value: 'Lakeland Terrier',
    },
    {
        label: 'Lancashire Heeler',
        value: 'Lancashire Heeler',
    },
    {
        label: 'Landseer',
        value: 'Landseer',
    },
    {
        label: 'Lapponian Herder',
        value: 'Lapponian Herder',
    },
    {
        label: 'Large Munsterlander',
        value: 'Large Munsterlander',
    },
    {
        label: 'Leonberger',
        value: 'Leonberger',
    },
    {
        label: 'Lhasa Apso',
        value: 'Lhasa Apso',
    },
    {
        label: 'Löwchen',
        value: 'Löwchen',
    },
    {
        label: 'Maltese',
        value: 'Maltese',
    },
    {
        label: 'Manchester Terrier',
        value: 'Manchester Terrier',
    },
    {
        label: 'Maremma Sheepdog',
        value: 'Maremma Sheepdog',
    },
    {
        label: 'Mastiff',
        value: 'Mastiff',
    },
    {
        label: 'Miniature Bull Terrier',
        value: 'Miniature Bull Terrier',
    },
    {
        label: 'Miniature Pinscher',
        value: 'Miniature Pinscher',
    },
    {
        label: 'Miniature Schnauzer',
        value: 'Miniature Schnauzer',
    },
    {
        label: 'Neapolitan Mastiff',
        value: 'Neapolitan Mastiff',
    },
    {
        label: 'Newfoundland',
        value: 'Newfoundland',
    },
    {
        label: 'Norfolk Terrier',
        value: 'Norfolk Terrier',
    },
    {
        label: 'Norwegian Buhund',
        value: 'Norwegian Buhund',
    },
    {
        label: 'Norwegian Elkhound',
        value: 'Norwegian Elkhound',
    },
    {
        label: 'Norwegian Lundehund',
        value: 'Norwegian Lundehund',
    },
    {
        label: 'Norwich Terrier',
        value: 'Norwich Terrier',
    },
    {
        label: 'Nova Scotia Duck Tolling Retriever',
        value: 'Nova Scotia Duck Tolling Retriever',
    },
    {
        label: 'Old English Sheepdog',
        value: 'Old English Sheepdog',
    },
    {
        label: 'Otterhound',
        value: 'Otterhound',
    },
    {
        label: 'Papillon',
        value: 'Papillon',
    },
    {
        label: 'Parson Russell Terrier',
        value: 'Parson Russell Terrier',
    },
    {
        label: 'Pekingese',
        value: 'Pekingese',
    },
    {
        label: 'Pembroke Welsh Corgi',
        value: 'Pembroke Welsh Corgi',
    },
    {
        label: 'Perro de Presa Canario',
        value: 'Perro de Presa Canario',
    },
    {
        label: 'Peruvian Hairless Dog',
        value: 'Peruvian Hairless Dog',
    },
    {
        label: 'Petit Basset Griffon Vendeen',
        value: 'Petit Basset Griffon Vendeen',
    },
    {
        label: 'Pharaoh Hound',
        value: 'Pharaoh Hound',
    },
    {
        label: 'Picardy Spaniel',
        value: 'Picardy Spaniel',
    },
    {
        label: 'Plott Hound',
        value: 'Plott Hound',
    },
    {
        label: 'Podenco Canario',
        value: 'Podenco Canario',
    },
    {
        label: 'Pointer',
        value: 'Pointer',
    },
    {
        label: 'Polish Greyhound',
        value: 'Polish Greyhound',
    },
    {
        label: 'Polish Hound',
        value: 'Polish Hound',
    },
    {
        label: 'Polish Lowland Sheepdog',
        value: 'Polish Lowland Sheepdog',
    },
    {
        label: 'Pomeranian',
        value: 'Pomeranian',
    },
    {
        label: 'Poodle',
        value: 'Poodle',
    },
    {
        label: 'Porcelaine',
        value: 'Porcelaine',
    },
    {
        label: 'Portuguese Podengo',
        value: 'Portuguese Podengo',
    },
    {
        label: 'Portuguese Pointer',
        value: 'Portuguese Pointer',
    },
    {
        label: 'Portuguese Sheepdog',
        value: 'Portuguese Sheepdog',
    },
    {
        label: 'Portuguese Water Dog',
        value: 'Portuguese Water Dog',
    },
    {
        label: 'Pug',
        value: 'Pug',
    },
    {
        label: 'Puli',
        value: 'Puli',
    },
    {
        label: 'Pumi',
        value: 'Pumi',
    },
    {
        label: 'Pyrenean Mastiff',
        value: 'Pyrenean Mastiff',
    },
    {
        label: 'Pyrenean Mountain Dog',
        value: 'Pyrenean Mountain Dog',
    },
    {
        label: 'Pyrenean Sheepdog',
        value: 'Pyrenean Sheepdog',
    },
    {
        label: 'Rafeiro do Alentejo',
        value: 'Rafeiro do Alentejo',
    },
    {
        label: 'Rhodesian Ridgeback',
        value: 'Rhodesian Ridgeback',
    },
    {
        label: 'Rottweiler',
        value: 'Rottweiler',
    },
    {
        label: 'Rough Collie',
        value: 'Rough Collie',
    },
    {
        label: 'Russian Black Terrier',
        value: 'Russian Black Terrier',
    },
    {
        label: 'Russian Toy',
        value: 'Russian Toy',
    },
    {
        label: 'Saarloos Wolfdog',
        value: 'Saarloos Wolfdog',
    },
    {
        label: 'Saint Bernard',
        value: 'Saint Bernard',
    },
    {
        label: 'Saluki',
        value: 'Saluki',
    },
    {
        label: 'Samoyed',
        value: 'Samoyed',
    },
    {
        label: 'Schapendoes',
        value: 'Schapendoes',
    },
    {
        label: 'Schiller Hound',
        value: 'Schiller Hound',
    },
    {
        label: 'Schipperke',
        value: 'Schipperke',
    },
    {
        label: 'Scottish Deerhound',
        value: 'Scottish Deerhound',
    },
    {
        label: 'Scottish Terrier',
        value: 'Scottish Terrier',
    },
    {
        label: 'Sealyham Terrier',
        value: 'Sealyham Terrier',
    },
    {
        label: 'Segugio Italiano',
        value: 'Segugio Italiano',
    },
    {
        label: 'Shar Pei',
        value: 'Shar Pei',
    },
    {
        label: 'Shetland Sheepdog',
        value: 'Shetland Sheepdog',
    },
    {
        label: 'Shiba Inu',
        value: 'Shiba Inu',
    },
    {
        label: 'Shih Tzu',
        value: 'Shih Tzu',
    },
    {
        label: 'Siberian Husky',
        value: 'Siberian Husky',
    },
    {
        label: 'Silky Terrier',
        value: 'Silky Terrier',
    },
    {
        label: 'Skye Terrier',
        value: 'Skye Terrier',
    },
    {
        label: 'Sloughi',
        value: 'Sloughi',
    },
    {
        label: 'Small Munsterlander',
        value: 'Small Munsterlander',
    },
    {
        label: 'Smooth Collie',
        value: 'Smooth Collie',
    },
    {
        label: 'Soft Coated Wheaten Terrier',
        value: 'Soft Coated Wheaten Terrier',
    },
    {
        label: 'Spanish Greyhound',
        value: 'Spanish Greyhound',
    },
    {
        label: 'Spanish Mastiff',
        value: 'Spanish Mastiff',
    },
    {
        label: 'Spanish Water Dog',
        value: 'Spanish Water Dog',
    },
    {
        label: 'Spinone Italiano',
        value: 'Spinone Italiano',
    },
    {
        label: 'Stabyhoun',
        value: 'Stabyhoun',
    },
    {
        label: 'Staffordshire Bull Terrier',
        value: 'Staffordshire Bull Terrier',
    },
    {
        label: 'Standard Schnauzer',
        value: 'Standard Schnauzer',
    },
    {
        label: 'Sussex Spaniel',
        value: 'Sussex Spaniel',
    },
    {
        label: 'Swedish Lapphund',
        value: 'Swedish Lapphund',
    },
    {
        label: 'Swedish Vallhund',
        value: 'Swedish Vallhund',
    },
    {
        label: 'Swiss Hound',
        value: 'Swiss Hound',
    },
    {
        label: 'Thai Ridgeback',
        value: 'Thai Ridgeback',
    },
    {
        label: 'Tibetan Mastiff',
        value: 'Tibetan Mastiff',
    },
    {
        label: 'Tibetan Spaniel',
        value: 'Tibetan Spaniel',
    },
    {
        label: 'Tibetan Terrier',
        value: 'Tibetan Terrier',
    },
    {
        label: 'Tosa Inu',
        value: 'Tosa Inu',
    },
    {
        label: 'Toy Poodle',
        value: 'Toy Poodle',
    },
    {
        label: 'Transylvanian Hound',
        value: 'Transylvanian Hound',
    },
    {
        label: 'Treeing Walker Coonhound',
        value: 'Treeing Walker Coonhound',
    },
    {
        label: 'Vizsla',
        value: 'Vizsla',
    },
    {
        label: 'Volpino Italiano',
        value: 'Volpino Italiano',
    },
    {
        label: 'Weimaraner',
        value: 'Weimaraner',
    },
    {
        label: 'Welsh Springer Spaniel',
        value: 'Welsh Springer Spaniel',
    },
    {
        label: 'Welsh Terrier',
        value: 'Welsh Terrier',
    },
    {
        label: 'West Highland White Terrier',
        value: 'West Highland White Terrier',
    },
    {
        label: 'Whippet',
        value: 'Whippet',
    },
    {
        label: 'White Swiss Shepherd Dog',
        value: 'White Swiss Shepherd Dog',
    },
    {
        label: 'Wire Fox Terrier',
        value: 'Wire Fox Terrier',
    },
    {
        label: 'Wirehaired Pointing Griffon',
        value: 'Wirehaired Pointing Griffon',
    },
    {
        label: 'Wirehaired Vizsla',
        value: 'Wirehaired Vizsla',
    },
    {
        label: 'Xoloitzcuintli',
        value: 'Xoloitzcuintli',
    },
    {
        label: 'Yorkshire Terrier',
        value: 'Yorkshire Terrier',
    },
    {
        label: 'Yugoslavian Shepherd Dog',
        value: 'Yugoslavian Shepherd Dog',
    },
    {
        label: 'Zwergschnauzer',
        value: 'Zwergschnauzer',
    },
    {
        label: 'Zwergspitz',
        value: 'Zwergspitz',
    },
]

export const CatBreed = [
    {
        label: 'Abyssin',
        value: 'Abyssin',
    },
    {
        label: 'Aegean',
        value: 'Aegean',
    },
    {
        label: 'American Curl',
        value: 'American Curl',
    },
    {
        label: 'American Shorthair',
        value: 'American Shorthair',
    },
    {
        label: 'American Wirehair',
        value: 'American Wirehair',
    },
    {
        label: 'Anatoli',
        value: 'Anatoli',
    },
    {
        label: 'Angora Turc',
        value: 'Angora Turc',
    },
    {
        label: 'Asian',
        value: 'Asian',
    },
    {
        label: 'Australian Mist',
        value: 'Australian Mist',
    },
    {
        label: 'Balinais',
        value: 'Balinais',
    },
    {
        label: 'Bengal',
        value: 'Bengal',
    },
    {
        label: 'Birman',
        value: 'Birman',
    },
    {
        label: 'Bleu Russe',
        value: 'Bleu Russe',
    },
    {
        label: 'Bobtail Américain',
        value: 'Bobtail Américain',
    },
    {
        label: 'Bobtail Japonais',
        value: 'Bobtail Japonais',
    },
    {
        label: 'Bombay',
        value: 'Bombay',
    },
    {
        label: 'British Longhair',
        value: 'British Longhair',
    },
    {
        label: 'British Shorthair',
        value: 'British Shorthair',
    },
    {
        label: 'Burmese',
        value: 'Burmese',
    },
    {
        label: 'Burmilla',
        value: 'Burmilla',
    },
    {
        label: 'Californian Spangled',
        value: 'Californian Spangled',
    },
    {
        label: 'Ceylan',
        value: 'Ceylan',
    },
    {
        label: 'Chantilly-Tiffany',
        value: 'Chantilly-Tiffany',
    },
    {
        label: 'Chartreux',
        value: 'Chartreux',
    },
    {
        label: 'Chausie',
        value: 'Chausie',
    },
    {
        label: 'Colorpoint Shorthair',
        value: 'Colorpoint Shorthair',
    },
    {
        label: 'Cornish Rex',
        value: 'Cornish Rex',
    },
    {
        label: 'Cymric',
        value: 'Cymric',
    },
    {
        label: 'Cyprus',
        value: 'Cyprus',
    },
    {
        label: 'Devon Rex',
        value: 'Devon Rex',
    },
    {
        label: 'Donskoy',
        value: 'Donskoy',
    },
    {
        label: 'Dragon Li',
        value: 'Dragon Li',
    },
    {
        label: 'Dwelf',
        value: 'Dwelf',
    },
    {
        label: 'Egyptian Mau',
        value: 'Egyptian Mau',
    },
    {
        label: 'European Shorthair',
        value: 'European Shorthair',
    },
    {
        label: 'Exotic Shorthair',
        value: 'Exotic Shorthair',
    },
    {
        label: 'Foldex',
        value: 'Foldex',
    },
    {
        label: 'German Longhair',
        value: 'German Longhair',
    },
    {
        label: 'German Rex',
        value: 'German Rex',
    },
    {
        label: 'Havana',
        value: 'Havana',
    },
    {
        label: 'Havana Brown',
        value: 'Havana Brown',
    },
    {
        label: 'Highland Fold',
        value: 'Highland Fold',
    },
    {
        label: 'Highland Lynx',
        value: 'Highland Lynx',
    },
    {
        label: 'Himalayan',
        value: 'Himalayan',
    },
    {
        label: 'Javanais',
        value: 'Javanais',
    },
    {
        label: 'Khao Manee',
        value: 'Khao Manee',
    },
    {
        label: 'Kohana',
        value: 'Kohana',
    },
    {
        label: 'Korat',
        value: 'Korat',
    },
    {
        label: 'Kurilian Bobtail',
        value: 'Kurilian Bobtail',
    },
    {
        label: 'LaPerm',
        value: 'LaPerm',
    },
    {
        label: 'Lykoi',
        value: 'Lykoi',
    },
    {
        label: 'Maine Coon',
        value: 'Maine Coon',
    },
    {
        label: 'Manx',
        value: 'Manx',
    },
    {
        label: 'Mekong Bobtail',
        value: 'Mekong Bobtail',
    },
    {
        label: 'Minuet',
        value: 'Minuet',
    },
    {
        label: 'Munchkin',
        value: 'Munchkin',
    },
    {
        label: 'Napoleon',
        value: 'Napoleon',
    },
    {
        label: 'Nebelung',
        value: 'Nebelung',
    },
    {
        label: 'Norvégien',
        value: 'Norvégien',
    },
    {
        label: 'Ocicat',
        value: 'Ocicat',
    },
    {
        label: 'Ojos Azules',
        value: 'Ojos Azules',
    },
    {
        label: 'Oriental Longhair',
        value: 'Oriental Longhair',
    },
    {
        label: 'Oriental Shorthair',
        value: 'Oriental Shorthair',
    },
    {
        label: 'Peke-Faced',
        value: 'Peke-Faced',
    },
    {
        label: 'Persan',
        value: 'Persan',
    },
    {
        label: 'Peterbald',
        value: 'Peterbald',
    },
    {
        label: 'Pixie Bob',
        value: 'Pixie Bob',
    },
    {
        label: 'Ragamuffin',
        value: 'Ragamuffin',
    },
    {
        label: 'Ragdoll',
        value: 'Ragdoll',
    },
    {
        label: 'Sacré de Birmanie',
        value: 'Sacré de Birmanie',
    },
    {
        label: 'Savannah',
        value: 'Savannah',
    },
    {
        label: 'Scottish Fold',
        value: 'Scottish Fold',
    },
    {
        label: 'Selkirk Rex',
        value: 'Selkirk Rex',
    },
    {
        label: 'Serengeti',
        value: 'Serengeti',
    },
    {
        label: 'Siamois',
        value: 'Siamois',
    },
    {
        label: 'Sibérien',
        value: 'Sibérien',
    },
    {
        label: 'Singapura',
        value: 'Singapura',
    },
    {
        label: 'Snowshoe',
        value: 'Snowshoe',
    },
    {
        label: 'Sokoke',
        value: 'Sokoke',
    },
    {
        label: 'Somali',
        value: 'Somali',
    },
    {
        label: 'Sphynx',
        value: 'Sphynx',
    },
    {
        label: 'Suphalak',
        value: 'Suphalak',
    },
    {
        label: 'Thaï',
        value: 'Thaï',
    },
    {
        label: 'Tiffany',
        value: 'Tiffany',
    },
    {
        label: 'Tonkinois',
        value: 'Tonkinois',
    },
    {
        label: 'Toyger',
        value: 'Toyger',
    },
    {
        label: 'Turc de Van',
        value: 'Turc de Van',
    },
    {
        label: 'Ural Rex',
        value: 'Ural Rex',
    },
    {
        label: 'York Chocolat',
        value: 'York Chocolat',
    },
]
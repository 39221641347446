import React, { useState } from 'react';
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import './healthRecord.css'
import InfoContainer from '../../component/infoContainer/InfoContainer';
import { HealthCardDrawer } from './drawer/HeatlhCardDrawer';
import { VetHistoryDrawer } from './drawer/VetHistoryDrawer';
import { ReminderDrawer } from './drawer/ReminderDrawer';
import { ReactComponent as PetProtection } from "../../assets/icons/healthRecord/PetProtection.svg";
import { ReactComponent as PetReport } from "../../assets/icons/healthRecord/PetReport.svg";
import { ReactComponent as Veterinarian } from "../../assets/icons/healthRecord/Veterinarian.svg";

function HealthSection() {
  const {t} = useTranslation();

  const [openDrawer, setOpenDrawer] = useState<boolean[]>([]);

  const sections = [
    {text: t("health-records-page.health-card"), svg: <PetReport/>},
    {text: t("health-records-page.veterinary-history"), svg: <Veterinarian/>},
    {text: t("health-records-page.prevention-vaccines"), svg: <PetProtection/>}
  ];

  const onDrawerClose = () => {
    setTimeout(() => {
      setOpenDrawer(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
      })
    }, 250);
  }

  return (
    <>
      <InfoContainer>
        <Flex justify='center' className='health-record-section-container' style={{ flexDirection: 'row', height: '180px', alignItems: 'center' }} gap={40}>
          {sections.map((section: any, key: number) => (
            <Flex
              key={key}
              className='health-record-btn'
              justify='center'
              style={{ height: '90%', width: '130px',flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                setOpenDrawer(prevState => {
                  const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = false;
                      return acc;
                  }, {});
                  newState[key] = !openDrawer[key];
                  return newState;
                })
              }}
              gap={5}
            >
              <Flex style={{ maxWidth: '89px', width: '100%', minWidth: '10px' }}>
                {section.svg}
              </Flex>
              <Typography.Text className='health-record-btn-text' style={{ width: '85px'}} strong>{section.text}</Typography.Text>
            </Flex>
          ))}
        </Flex>
      </InfoContainer>
      {openDrawer[0] && <HealthCardDrawer onClose={onDrawerClose}/>}
      {openDrawer[1] && <VetHistoryDrawer onClose={onDrawerClose}/>}
      {openDrawer[2] && <ReminderDrawer onClose={onDrawerClose}/>}
    </>
  );
}

export default HealthSection;

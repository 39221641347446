import React from 'react';

import HealthSection from '../../component/healthRecord/HealthSection';
import DisplayWeight from '../../component/weight/DisplayWeight';

function HealthRecord() {
  return (
    <div className="HealthRecord" style={{ textAlign: 'center' }}>
      <DisplayWeight/>
      <HealthSection/>
    </div>
  );
}

export default HealthRecord;

import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Typography } from "antd";
import { GoTrash } from "react-icons/go";
import { LeftOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

import '../activity.css'
import { deleteSingleActivity, deleteActivity, updateActivity } from "../../../request/activity";
import DeleteConfirm from "../../confirm/DeleteConfirm";
import { dateFormat } from "../../../config/format";
import { isDateToday } from "../../weight/drawer/WeightDrawer";
import { customTheme } from "../../../config/theme";
import PetAvatar from "../../pets/PetAvatar";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import CustomTimePicker from "../../customPicker/CustomTimePicker";
import { useTranslation } from "react-i18next";

type Props = {
    activity: ActivityDetails,
    petAvatar: {species: string, avatar: string, id: string},
    renew: () => void,
    onClose: (reRender: boolean) => void
}

type ActivityDetails = {
    activity: {created_at: string, duration: string, id: number}[];
    created_at: string;
    pet_id: string;
};

function formatDate(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [date, time] = dateToFormat.split(' ')
        const [day, month, year] = date.split('-');

        return dayjs(`${year}-${month}-${day} ${time}`)
    }
    return dateToFormat;
}

function formatTime(timeToFormat: string) {
    const [h, m, s] = timeToFormat.split(':');

    return dayjs().hour(Number(h)).minute(Number(m)).second(Number(s));
}

function ActivityDetailsDrawer(props: Props) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const [openModifyActivity, setOpenModifyActivity] = useState<boolean>(false);
    const [openActivity, setOpenActivity] = useState<boolean[]>([]);

    const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
    const [activity, setActivity] = useState<dayjs.Dayjs>(dayjs());

    const [activities, setActivites] = useState<any>(props.activity);

    const handleSubmit = async (index: number) => {
        try {
            const formattedDate = date.format(`${dateFormat} HH:mm`)
            const formattedTime = activity.format('HH:mm:ss')

            await updateActivity({created_at: formattedDate, duration: formattedTime, id: index}, activities._id);
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.renew) {
            props.renew()
        }
    }

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    }

    const handleActivityClick = (key: number) => {
        setOpenActivity(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = !openActivity[key];
            return newState;
        })
        setOpenModifyActivity(false)
    }

    const removeActivities = async () => {
        try {
            await deleteActivity(activities._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.onClose) {
            props.onClose(true)
        }
    }

    const removeActivity = async (index: number) => {
        try {
            await deleteSingleActivity(activities._id, index)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.renew) {
            props.renew()
        }
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    useEffect(() => {
        setActivites(props.activity)
    }, [props.activity])

    return (
        <Drawer
            title={`${activities.created_at} ${isDateToday(activities.created_at) ? ` (AUJOURD'HUI)` : '' } - ${activities.duration}`}
            footer={<DeleteConfirm delete={removeActivities}>Supprimer</DeleteConfirm>}
            onClose={() => handleClose(false)}
            open={open}
            width={650}
            closeIcon={<LeftOutlined />}
        >
            <Flex gap={'13px'} style={{ flexDirection: 'column' }}>
                {activities && activities.activity.map((_activity: any, key: any) => (
                    <Card
                        key={key}
                        {...!openActivity[key] ? { onClick: () => handleActivityClick(key) } : null}
                        style={{ height: 'auto', minWidth: '100px', ...openActivity[key] === true && {boxShadow: '0px 4px 10px rgb(0, 0, 0, 0.2)' } }}
                        hoverable={!openActivity[key]}
                    >
                        <Flex
                            style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                            onClick={() => handleActivityClick(key)}
                        >
                            <PetAvatar species={props.petAvatar.species} url={props.petAvatar.avatar} iconSize={{width: "30px", height: "30px"}}/>
                            {' '}
                            <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                            <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                {_activity.created_at} - {_activity.duration}
                            </Typography.Text>
                        </Flex>
                        <Flex
                            gap={'10px'}
                            style={{
                                flexDirection: 'column',
                                maxHeight: openActivity[key] ? '300px' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.5s ease'
                            }}
                        >
                            {openActivity[key] && openModifyActivity &&
                                <Flex gap={'5px'} style={{ flexDirection: 'row', marginTop: '5px' }} className="drawer-weight-modify">
                                    <CustomTimePicker
                                        value={activity}
                                        placeholder={t("activity-section.activity-placeholder")}
                                        style={{ width: '100%' }}
                                        format={"HH:mm:ss"}
                                        onChange={(e: number | any) => setActivity(e)}
                                    />
                                    <CustomDatePicker
                                        disabledDate={disableFutureDates}
                                        defaultValue={date}
                                        style={{ width: '100%' }}
                                        placeholder={t("appointment-creation.date-placeholder")}
                                        format={`${dateFormat}`}
                                        onChange={(e: any) => setDate(e as any)}
                                    />
                                </Flex>
                            }
                            <Flex justify="space-between">
                                <Flex justify='flex-start' gap={'8px'} style={{ flexDirection: 'row' }}>
                                    <Button
                                        className='drawer-activity-btn'
                                        onClick={() => {
                                            handleActivityClick(key)
                                            removeActivity(_activity.id)
                                        }}
                                        type="default"
                                        style={{ border: 'none', outline: 'none', boxShadow: 'none' }}
                                        icon={<GoTrash/>}
                                    />
                                </Flex>
                                <Flex justify='flex-end' gap={'8px'} style={{ flexDirection: 'row' }}>
                                    <Button
                                        className='drawer-activity-btn'
                                        type="primary"
                                        style={{ width: '82px' }}
                                        onClick={() => {
                                            setOpenModifyActivity(!openModifyActivity)
                                            setActivity(formatTime(_activity.duration));
                                            setDate(formatDate(_activity.created_at));
                                        }}
                                    >
                                        {openModifyActivity
                                            ? t("form.cancel")
                                            : t("pet-profile.modify")}
                                    </Button>
                                    {openModifyActivity &&
                                        <Button
                                            className='drawer-activity-btn'
                                            style={{ width: '96px' }}
                                            disabled={(activity == null) || (date === null)}
                                            onClick={() => {
                                                setOpenModifyActivity(false);
                                                handleSubmit(_activity.id)
                                            }}
                                        >
                                            {t("form.save")}
                                        </Button>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Card>
                ))}
            </Flex>
        </Drawer>
    );
}


export { ActivityDetailsDrawer }

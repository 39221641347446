import { MessageOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import FeedbackModal from "./FeedbackModal";

function FeedbackButton({onSidebar}: {onSidebar?: boolean}) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                type="primary"
                {...onSidebar ? { style: { position: 'absolute', bottom: '20px', left: '20px', zIndex: '1000'} } : null}
                onClick={() => setOpen(true)}
                icon={<MessageOutlined />}
            >
                {t('feedback.give-us-feedback')} !
            </Button>
            <FeedbackModal open={open} setOpen={setOpen}/>
        </>
    )
}

export default FeedbackButton;
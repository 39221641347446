import React, { useEffect, useState } from "react";
import { Flex, Typography } from "antd";
import { CartesianGrid, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import dayjs from 'dayjs';

import { customTheme } from "../../config/theme";
import { WeightDetails } from "./DisplayWeight";
import { IdealWeightCat, IdealWeightDog } from './idealWeight'
import { getUserSelectedPet } from "../../request/user/pet";
import { useTranslation } from "react-i18next";

type PropsWeightChart = {
    weight: WeightDetails[] | null,
    setWeightrange?: React.Dispatch<React.SetStateAction<{min: number, max: number}>>
}

export function WeightChart(props: PropsWeightChart) {
    const {t} = useTranslation();

    const [idealWeightRange, setIdealWeightRange] = useState({min: 0, max: 0})
    const [showRefArea, setShowRefArea] = useState(true);

    const CustomTooltipWeight = ({ active, payload, label }: any) => {
        if ((active && payload && payload.length) && props.weight != null) {
            const { _id, created_at } = payload[0].payload;
            const weightNumber = props.weight.find((_weight: any) => _weight._id === _id)?.weight;

            return (
                <Flex className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', flexDirection: 'column' }}>
                    <Typography.Text>{`${created_at}`}</Typography.Text>
                    <Typography.Text style={{ color: customTheme.colorPrimary }}>{`${t("weight-section.weight-tooltip")}: ${weightNumber}`}</Typography.Text>
                </Flex>
            );
        }
        return (
            null
        );
    };

    const getIdealWeightRange = async () => {
        // TODO : look if only api call could be done
        const result = await getUserSelectedPet();
        let range = {min: 0, max: 0};
        const diff = getDateDiffFromToday(result?.birth_date);

        if (diff > 0) {
            setShowRefArea(true)
            if (result.species == "dog") {
                const index: number = await IdealWeightDog.findIndex((v) => v.value == result.breed);
                if (result.gender == "Mâle" && index >= 0)
                    range = {min: IdealWeightDog[index].male[0], max: IdealWeightDog[index].male[1]};
                else if (index >= 0)
                    range = {min: IdealWeightDog[index].female[0], max: IdealWeightDog[index].female[1]};
            } else {
                const index: number = await IdealWeightCat.findIndex((v) => v.value == result.breed);
                if (result.gender == "Mâle" && index >= 0)
                    range = {min: IdealWeightCat[index].male[0], max: IdealWeightCat[index].male[1]};
                else if (index >= 0)
                    range = {min: IdealWeightCat[index].female[0], max: IdealWeightCat[index].female[1]};
            }
            setIdealWeightRange(range);
            if (props.setWeightrange) {
                props.setWeightrange(range);
            }
        } else {
            setShowRefArea(false)
        }
    }

    useEffect(() => {
        getIdealWeightRange()
    }, [props.weight])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '160px' }}>
            <ResponsiveContainer width="100%" height='100%'>
                <LineChart
                    width={550}
                    height={200}
                    data={props.weight || []}
                    className='weight-graph'
                    style={{ maxWidth: '600px', minWidth: '100px' }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {showRefArea &&
                        <ReferenceArea
                            y1={idealWeightRange.min} y2={idealWeightRange.max}
                            fill={customTheme.colorPrimary} fillOpacity={0.2}
                            ifOverflow="extendDomain"
                        />
                    }
                    <XAxis dataKey="created_at" hide/>
                    <Tooltip content={<CustomTooltipWeight/>}/>
                    <Line type="monotone" dataKey="weight" stroke={customTheme.colorPrimary} strokeWidth={2}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

function getDateDiffFromToday(date: string) {
    if (date) {
        const [day, month, year] = date.split('/');
        const diff = dayjs().diff(dayjs(`${year}-${month}-${day}`), 'year');
        return diff;
    } else {
        return 0;
    }
}

export default WeightChart
import { Button, Card, Divider, Flex, Form, Grid, Input, Layout, Modal, Space, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './profile.css'
import { PlusOutlined } from '@ant-design/icons';
import { createVet } from '../../request/vet';
import { customTheme } from '../../config/theme';

function AddVets(props: any) {
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const [showModal, setShowModal] = useState<boolean>(false)

    const [vetClinic, setVetClinic] = useState<string>("");
    const [vetName, setVetName] = useState<string>("");
    const [vetEmail, setVetEmail] = useState<string>("");
    const [vetPhoneNumber, setVetPhoneNumber] = useState<string>("");

    const [errorText, setErrorText] = useState<string>("");

    const resetVet = () => {
        setVetName("")
        setVetClinic("")
        setVetEmail("")
        setVetPhoneNumber("")
    }

    const handleSubmit = async () => {
        try {
            const vet = { vet_clinic: vetClinic, vet_name: vetName, vet_email: vetEmail, vet_phone_number: vetPhoneNumber };
            const result = await createVet(vet);

            if (result && result?.status === 200 && result?.data.msg === 'Vet created') {
                message.success(t("vet-creation.success"));
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to add vet') {
                message.error(t("vet-creation.error"));
            }
        } catch (error : any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setShowModal(false);
        if (props.renew) {
            props.renew();
        }
        resetVet();
    }

    const validateFields = () => {
        form.validateFields(['vetName', 'vetClinic'])
            .then(() => setErrorText(''))
            .catch(() => setErrorText(t("vet-creation.fill-name-clinic")));
    };

  return (
    <>
        <Layout>
            <Layout.Content style={{ display: 'flex', backgroundColor: customTheme.colorBackground }}>
                <Flex style={{ flexDirection: 'column', width: '100%'}}>
                    {showModal &&
                        <Modal
                            open={true}
                            height={'auto'}
                            title={t("vet-creation.add-vet")}
                            okText={t("form.add")}
                            cancelText={t("form.cancel")}
                            onCancel={() => {
                                setShowModal(false)
                                form.resetFields()
                                setVetName("");
                                setVetClinic("");
                                setVetPhoneNumber("");
                                setVetEmail("");
                                setErrorText('')
                            }}
                            onOk={() => {
                                    form.submit();
                                }
                            }
                            destroyOnClose
                            modalRender={(dom) => (
                                <Form
                                    layout="vertical"
                                    style={{ maxWidth: 600 }}
                                    form={form}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={handleSubmit}
                                    variant='filled'
                                    clearOnDestroy
                                >
                                    {dom}
                                </Form>
                            )}
                        >
                            <Flex style={{ flexDirection: 'column' }}>
                                <Flex className='profile-container-responsive' style={{ flexDirection: 'row' }} gap={'4%'}>
                                    <Form.Item
                                        label={t("vet-creation.name")}
                                        name="vetName"
                                        className='profile-content-responsive'
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                validator: async () => {
                                                    if (!vetName && !vetClinic) {
                                                        setErrorText(t("vet-creation.fill-name-clinic"))
                                                        throw new Error();
                                                    }
                                                },
                                            }
                                        ]}
                                    >
                                        <Input
                                            addonBefore={t("vet-creation.doctor")}
                                            placeholder={t("vet-creation.name-placeholder")}
                                            onChange={(e) => {
                                                setVetName(e.target.value)
                                                validateFields()
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("vet-creation.clinic")}
                                        name="vetClinic"
                                        className='profile-content-responsive'
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                validator: async () => {
                                                    if (!vetName && !vetClinic) {
                                                        setErrorText(t("vet-creation.fill-name-clinic"))
                                                        throw new Error();
                                                    }
                                                },
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t("vet-creation.clinic-placeholder")}
                                            onChange={(e) => {
                                                setVetClinic(e.target.value)
                                                validateFields()
                                            }}
                                        />
                                    </Form.Item>
                                </Flex>
                                <Typography.Text style={{ color: 'red', fontWeight: 'lighter', marginTop: '-15px', marginBottom: '10px' }}>{errorText}</Typography.Text>
                            </Flex>
                            <Flex className='profile-container-responsive' style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Item
                                    label={t("vet-creation.email")}
                                    name="vetEmail"
                                    className='profile-content-responsive'
                                    style={{ width: '48%' }}
                                >
                                    <Input placeholder={t("vet-creation.email-placeholder")} onChange={(e) => setVetEmail(e.target.value)}/>
                                </Form.Item>
                                <div style={{ width: '4%' }}/>
                                <Form.Item
                                    label={t("vet-creation.phone-number")}
                                    name="vetPhoneNumber"
                                    className='profile-content-responsive'
                                    style={{ width: '48%' }}
                                >
                                    <Input placeholder={t("vet-creation.phone-number-placeholder")} onChange={(e) => setVetPhoneNumber(e.target.value)}/>
                                </Form.Item>
                            </Flex>
                        </Modal>
                    }
                    {!showModal &&
                        <Flex style={{ display: 'flex', justifyContent: 'center', backgroundColor: props.backgroundColor || 'transparent'  }}>
                            <Button
                                type="dashed"
                                onClick={() => setShowModal(true)}
                                style={{ width: '60%' }}
                                icon={<PlusOutlined />}
                            >
                                {t("vet-creation.add-vet")}
                            </Button>
                        </Flex>
                    }
                </Flex>
            </Layout.Content>
        </Layout>
    </>
  );
}

export default AddVets;

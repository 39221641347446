import React, { useEffect, useState } from "react"
import { Card, Divider, Drawer, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { TFunction } from "i18next";

import './calendarDrawer.css'
import { FaCat, FaDog } from "react-icons/fa";
import { EventDrawerAppointment } from "./AppointmentDrawer";
import { EventDrawerTreatment } from "./TreatmentDrawer";
import { customTheme } from "../../../config/theme";
import { useTranslation } from "react-i18next";

export function displayDate(date: string, t: TFunction<"translation", undefined>) {
    const months = [
        t("month.january"), t("month.february"), t("month.march"), t("month.april"), t("month.may"), t("month.june"),
        t("month.july"), t("month.august"), t("month.september"), t("month.october"), t("month.november"), t("month.december")
    ];
    const [year, month, day] = date.split("-")

    return `${day} ${months[Number(month) - 1]} ${year}`
}

function EventDrawerGridView(props: any) {
    const {t} = useTranslation();

    const [open, setOpen] = useState<boolean>(true);

    const [drawerEvent, setDrawerEvent] = useState<{event: any}>();
    const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const onDrawerClose = (noUse: boolean) => {
        setTimeout(() => {
          setOpenStateDrawer(false)
        }, 250);
    }

    const getTranslatedEventType = (type: string) => {
        const appointmentTranslateSearch = `appointment-creation.appointment-type.${type}`
        const treatmentReminderTranslateSearch = `treatment-reminder-creation.treatment-reminder-type.${type}`

        if (appointmentTranslateSearch != t(appointmentTranslateSearch)) {
          return t(appointmentTranslateSearch);
        } else if (treatmentReminderTranslateSearch != t(treatmentReminderTranslateSearch)) {
          return t(treatmentReminderTranslateSearch);
        }
        return type;
      }

    return (
        <>
            <div>
                {openStateDrawer &&
                    <>
                        {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
                            <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={props.renew}/>
                        ) : (
                            <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={props.renew}/>
                        )}
                    </>
                }
            </div>
            <Drawer
                title={`${displayDate(props.events.month, t)}`}
                onClose={handleClose}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                className={`drawer-grid-view ${openStateDrawer ? "active" : ''}`}
                >
                <>
                    {props.events.events.map((event: any, key: any) => (
                        <>
                            <div style={{ marginBottom: '20px' }}>
                                <Card
                                    key={key}
                                    style={{ height: '50px', width: 'auto', cursor: 'pointer', maxWidth: '600px',minWidth: '100px' }}
                                    onClick={() => {
                                        setDrawerEvent(event);
                                        setOpenStateDrawer(true)
                                    }}
                                    hoverable
                                >
                                    {event.pet.petSpecies && event.pet.petSpecies === 'dog' ? (
                                        <FaCat/>
                                    ) :(
                                        <FaDog/>
                                    )}
                                    {' '}
                                    <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                    <Typography.Text style={{ fontSize: '17px' }}>{(event.date).split(" ")[0]} - {getTranslatedEventType(event.name)}</Typography.Text>
                                </Card>
                            </div>
                        </>
                    ))}
                </>
            </Drawer>
        </>
    );
}

export { EventDrawerGridView }
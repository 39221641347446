import { TimePicker } from "antd";

function handleOpenChange(open: boolean) {
    if (open) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
}

function CustomTimePicker(props: any) {
    return (
        <TimePicker
            inputReadOnly={true}
            placement={"topRight"}
            onOpenChange={handleOpenChange}
            {...props}
        />
    );
}

export default CustomTimePicker;

import React, { useEffect } from 'react';

import { useGlobalState } from '../../../state/globalState';
import { getPetsFromUser } from '../../../request/pets';
import { Treatment } from './TreatmentModal';
import { Appointment } from './AppointmentModal';

type PropsCalendarModal = {
    selectedEvent: "treatment-reminder" | "appointment",
    onSubmit: (submit: boolean) => void
}

function CalendarModal(props: PropsCalendarModal) {
    const [userPet, setUserPet] = useGlobalState('userPets');

    const closeModal = (submit: boolean) => props.onSubmit(submit);

    const getPetList = async () => {
        setUserPet(await getPetsFromUser());
    }

    useEffect(() => {
        if (userPet[0] === "") {
            getPetList();
        }
    }, []);

  return (
    <div className="CalendarModal">
        {props.selectedEvent === "treatment-reminder" ? (
            <Treatment pets={userPet} onClose={closeModal}/>
        ) : (
            <Appointment pets={userPet} onClose={closeModal}/>
        )}
    </div>
  );
}

export default CalendarModal;

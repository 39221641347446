import React, { useEffect, useState } from 'react';
import { Flex, Select } from 'antd';

import './selectPet.css'
import { useGlobalState } from '../../state/globalState';
import { getPetsFromUser } from '../../request/pets';
import { getUserSelectedPet, createSelectedPetCookies } from '../../request/user/pet';
import PetAvatar, { assignPetAvatar, savePetAvatars } from '../pets/PetAvatar';

interface FormattedPet {
  label: string,
  value: string,
  species: string,
  avatar: string
}

const calculateMaxOptionWidth = (options: any[]) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = '14px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';

  let maxWidth = 0;
  options.forEach(option => {
    const width = context!.measureText(option.label).width;
    if (width > maxWidth) {
      maxWidth = width;
    }
  });
  return maxWidth + 50;
};

function SelectPet(props: any) {
  const [reRender, setReRender] = useGlobalState('reRender');
  const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');
  const [userPets, setUserPets] = useGlobalState('userPets');
  const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');
  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');

  const [formatedPetList, setFormatedPetList] = useState<FormattedPet[]>([])
  const [selectedPet, setSelectedPet] = useState("");


  const formatPetList = async (userPet: any) => {
    let newPetList: FormattedPet[] = []

    if (userPet && userPet[0] !== "") {
      for (let i = 0; i < userPet.length; i++) {
        newPetList.push({label: userPet[i].name, value: userPet[i]._id, species: userPet[i].species, avatar: userPet[i].avatar})
      }
    }
    return newPetList;
  }

  const changeUserSelectedPet = async (value: string, object: any) => {
    if (props.changeCookies) {
      await createSelectedPetCookies(value);

      setSelectedPetName(object?.label || "");
      setSelectedPet(value)
    } else if (!props.changeCookies) {
      setSelectedPet(object.label);
      props.onChange({name: object?.label, species: object?.species, pet_id: object?.value});
      return;
    }
    if (props.onChange) {
      props.onChange();
    }
  }

  const initSelectPet = async () => {
    const result = await getPetsFromUser();

    if (props.changeCookies) {
      await savePetAvatars(result.avatars, false);
      setReRender(new Date());
    }
    const pets = await assignPetAvatar(result.pets, "_id", "avatar", true);
    if (pets) {
      setNumberOfPets(pets.length);
    }
    setUserPets(pets);
    setFormatedPetList(await formatPetList(pets));
    if (props.pet) {
      setSelectedPet(props.pet);
    } else {
      const response = await getUserSelectedPet();
      if (response && pets) {
        if (pets.length >= 1 && response.status === 401 && response.data.msg === 'No pet selected') {
          changeUserSelectedPet(pets[0]._id, {label: pets[0]?.name});
        } else {
          setSelectedPetName(response.name);
          setSelectedPet(response._id);
        }
      }
    }
  }

  const [dynamicWidth, setDynamicWidth] = useState("150px");

  // useEffect(() => {
  //   if (formatedPetList && formatedPetList.length > 0) {
  //     const calculatedWidth = calculateMaxOptionWidth(formatedPetList);
  //     setDynamicWidth(calculatedWidth);
  //   }
  // }, [formatedPetList]);

  useEffect(() => {
    initSelectPet()
  }, [props.pet, reRenderSelectPet]);

  return (
    <>
      {formatedPetList !== null && numberOfPets >= 1 ? (
        <>
          <Select
            value={selectedPet}
            style={{ width: props.width || dynamicWidth, height: props.height || '' }}
            placeholder='Sélectionner votre animal'
            className={props.className || ''}
            disabled={props.disabled || false}
            onChange={(value) => {
              const petObject = formatedPetList.find(pet => pet.value == value)

              changeUserSelectedPet(value, petObject)
            }}
          >
            {formatedPetList.map((pet: FormattedPet, key: number) =>  (
              <Select.Option key={key} value={pet.value}>
                <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                  <PetAvatar species={pet.species} url={pet.avatar} iconSize={{width: "30px", height: '30px'}} imageSize={{width: "30px", height: '30px'}}/>
                  {pet.label}
                </Flex>
              </Select.Option>
            ))}
          </Select>
        </>
      ) : (
        null
      )}
    </>
  );
}

export default SelectPet;


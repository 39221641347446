import axios from 'axios';

const getWeightFromPet = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/weight`,
        {
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return null;
    }
}

const createWeight = async (weight: number, date: string) => {
  try {
      const data = JSON.stringify({weight, created_at: date}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/weight `,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const deleteWeight = async (id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/weight/${id}`,
      {
        withCredentials: true,
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const updateWeight = async (weight: number, date: string, id: number) => {
  try {
    const data = JSON.stringify({weight, created_at: date, id}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/weight `,
      data,
      {
          headers: {
              "Content-Type": "application/json"
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

export {
  getWeightFromPet,
  createWeight,
  deleteWeight,
  updateWeight
}
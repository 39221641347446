import { FlagOutlined } from "@ant-design/icons";
import { Button, Flex, Select, Tooltip, Typography } from "antd";
import { changeLanguage } from "i18next";

import { useEffect, useState } from "react";
import './ChangeLang.css'

interface ChangeLangage {
    dropDownMode?: boolean,
}

function ChangeLang({dropDownMode}: ChangeLangage) {
    const [selectedLangage, setSelectedLangage] = useState("en");

    function changeLanguageAndLocalStorage(lang: string) {
        changeLanguage(lang);
        localStorage.setItem('savedLanguage', lang);
        document.documentElement.lang = lang;
        setSelectedLangage(lang)
    }

    const langage = [
        {label: "English", value: "en", tooltip: "Switch to English"},
        {label: "Français", value: "fr", tooltip: "Passer au français"},
    ]

    useEffect(() => {
        const lang = localStorage.getItem('savedLanguage');

        if (lang) {
            setSelectedLangage(lang)
        } else if (!lang) {
            changeLanguageAndLocalStorage(selectedLangage);
        }
    }, [])

    return (
        <>
            {dropDownMode
                ?   <Flex align="center" justify="center" gap={"small"}>
                        <Select
                                value={selectedLangage}
                                style={{ width: '100px', height: "40px" }}
                                onChange={(value) => changeLanguageAndLocalStorage(value)}
                                className="change-lang"
                            >
                                {langage.map((value, key: number) =>  (
                                    <Select.Option key={key} value={value.value}>
                                        <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                                            <Flex style={{ width: "25px" }}>
                                                <img
                                                    src={`assets/images/flags/${value.value}.png`}
                                                    width={'100%'}
                                                />
                                            </Flex>
                                            <Typography.Text className="change-lang-text">{value.value.toUpperCase()}</Typography.Text>
                                        </Flex>
                                    </Select.Option>
                                ))}
                            </Select>
                    </Flex>
                :   <Flex align="center" justify="center" gap={"small"}>
                        {langage.map((value, key: number) => (
                            <Tooltip title={value.tooltip} key={key}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    icon={<FlagOutlined />}
                                    onClick={() => changeLanguageAndLocalStorage(value.value)}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {value.label}
                                </Button>
                            </Tooltip>
                        ))}
                    </Flex>
            }
        </>
    );
}

export default ChangeLang;
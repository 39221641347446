import axios from 'axios';

type VetDetails = {
    vet_clinic: string;
    vet_name: string;
    vet_email: string;
    vet_phone_number: string;
}

const getVetFromUser = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/vet`,
            {
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

const createVet = async (vet: VetDetails) => {
    try {
        const data = JSON.stringify({vet}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/vet`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const updateVet = async (vetName: string, vetClinic: string, vetEmail: string, vetPhoneNumber: string, id: string) => {
    try {
      const data = JSON.stringify({vet_name: vetName, vet_clinic: vetClinic, vet_email: vetEmail, vet_phone_number: vetPhoneNumber, id}, null, 1)
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/vet `,
        data,
        {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        },
      );
    } catch (error: any) {
         console.log(`there was an error: ${error}`);
    }
}

const deleteVet = async (id: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/vet/${id}`,
        {
            withCredentials: true
        },
      );
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
    }
}

export {
    getVetFromUser,
    createVet,
    updateVet,
    deleteVet
}
import React, { useEffect, useState } from "react"
import { Button, Divider, Drawer, Flex, Form, Input, Modal, Typography } from "antd";
import dayjs from 'dayjs';

import './calendarDrawer.css'
import { CodeSandboxCircleFilled, ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { updateEvent, deleteEvent } from "../../../request/events";
import SelectPet from "../../navBar/SelectPet";
import DeleteConfirm from "../../confirm/DeleteConfirm";
import { dateFormat } from "../../../config/format";
import TreatmentValidationModal from "../modal/TreatmentValidationModal";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

function formatDate(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [day, month, year] = dateToFormat.split('-');
        const formattedDateTime = `${year}-${month}-${day}`;

        return dayjs(formattedDateTime)
    }
    return dateToFormat;
}

function formatDateEvent(dateToFormat: string) {
    const [date, time] = dateToFormat.split(' ');
    const [day, month, year] = date.split('-');

    return `${year}-${month}-${day}`;
}

function getRenewSentence(event: any, t: TFunction<"translation", undefined>) {
    const today = new Date().setHours(0, 0, 0, 0);
    const eventDate = new Date(formatDateEvent(event.date)).getTime();
    const diffInDays = Math.floor((eventDate - today) / (1000 * 60 * 60 * 24));

    if (diffInDays < 0) {
        return t("treatment-reminder-drawer.renew-past", { diffInDays: Math.abs(diffInDays) });
    } else if (diffInDays === 0) {
        return t("treatment-reminder-drawer.renew-today");
    } else {
        return t("treatment-reminder-drawer.renew-comming", { diffInDays: diffInDays });
    }
}

function EventDrawerTreatment(props: any) {
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [open, setOpen] = useState(true);

    const [modify, setModify] = useState(false);
    const event = props.event;
    const isRenewable = ['Vaccin', 'Vermifuge', 'Anti-parasitaire'].includes(event.name);

    const [pet, setPet] = useState<{pet_id: string, species: string, name: string}>(event.pet);

    const [date, setDate] = useState(formatDate(event.date));
    const [treatment, setTreatment] = useState(event.treatment);
    const [notes, setNotes] = useState(event.notes);
    const [modalVisible, setModalVisible] = useState(false);

    const changeEvent = () => {
        const formattedDate = new Date(date.toDate()).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });

        event.pet = pet;
        event.treatment = treatment;
        event.notes = notes;
        event.date = `${formattedDate}`;
    }

    const handleSubmit = async () => {
        try {
            const newDate = dayjs(date).format(`${dateFormat}`);

            await updateEvent({ pet_id: pet.pet_id }, newDate, treatment, "", notes, event._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setModify(false);
        if (props.renew) {
            props.renew();
        }
        changeEvent();
    }

    const handleSelectPet = (pet: {pet_id: string, species: string, name: string}) => {
        setPet({pet_id: pet.pet_id, species: pet.species, name: pet.name});
    }

    const removeEvent = async () => {
        try {
            await deleteEvent(event._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        handleClose(true);
    }

    const handleModify = () => {
        if (modify) {
            setPet(event.pet);
            setDate(formatDate(event.date));
            setNotes(event.notes)
        }
        setModify(!modify);
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    const getTranslatedTreatmentRemindeType = (type: string) => {
        const treatmentReminderTranslateSearch = `treatment-reminder-creation.treatment-reminder-type.${type}`

        if (treatmentReminderTranslateSearch != t(treatmentReminderTranslateSearch)) {
          return t(treatmentReminderTranslateSearch);
        }
        return type;
    }


    useEffect(() => {
        setOpen(true)
    }, []);

    return (
        <>
            <Drawer
                title={`${(event.date).split(' ')[0]} - ${getTranslatedTreatmentRemindeType(event.name)}`}
                extra={
                    <Button onClick={() => handleModify()}>
                        {!modify ? t("pet-profile.modify") : t("form.cancel")}
                    </Button>
                }
                footer={<DeleteConfirm delete={removeEvent}>{t("form.remove")}</DeleteConfirm>}
                onClose={() => handleClose(false)}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
            >
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.pet")} : </Typography.Text>
                    <SelectPet
                        pet={pet.name}
                        changeCookies={false}
                        onChange={handleSelectPet}
                        disabled={!modify}
                        width='100%'
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>Date :</Typography.Text>
                    <CustomDatePicker
                        value={date}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        onChange={(e: any) => setDate(e as any)}
                        format={`${dateFormat}`}
                        disabled={!modify}
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("treatment-reminder-drawer.treatment")} :</Typography.Text>
                    <Input.TextArea
                        rows={4}
                        value={treatment}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        autoSize={{ minRows: 2 }}
                        disabled={!modify}
                        onChange={(e) => setTreatment(e.target.value)}
                    />
                </Flex>
                <Flex style={{ flexDirection: 'column', marginBottom: '10px' }}>
                    <Typography.Text style={{ fontWeight: 'bold' }}>{t("appointment-creation.additional-information")} :</Typography.Text>
                    <Input.TextArea
                        rows={4}
                        value={notes}
                        style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                        autoSize={{ minRows: 3 }}
                        disabled={!modify}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </Flex>
                {event.to_renew && event.important &&
                    <>
                        <Divider />
                        <Flex style={{ flexDirection: 'row', marginBottom: '10px', alignItems: 'center'}}>
                            <ExclamationCircleOutlined
                            style={{
                                color: 'red',
                                fontSize: '20px',
                                top: '50%',
                                marginRight: '10px',
                            }}
                            />
                            <Typography.Text style={{ fontWeight: 'bold' }}>{getRenewSentence(event, t)}</Typography.Text>
                        </Flex>
                    </>
                }
                <Flex gap={10}>
                    {event.to_renew &&
                        <>
                            <Button
                                type="primary"
                                style={{ marginTop: '10px' }}
                                onClick={() => setModalVisible(true)}
                            >
                                {t("treatment-reminder-drawer.validate")}
                            </Button>
                            <TreatmentValidationModal
                                open={modalVisible}
                                onFinish={(success: boolean) => {
                                    setModalVisible(false)
                                    handleClose(success);
                                }}
                                event={event}
                            />
                        </>
                    }
                    {modify &&
                        <Button
                            type="primary"
                            style={{ marginTop: '10px' }}
                            onClick={handleSubmit}
                        >
                            {t("form.save")}
                        </Button>
                    }
                </Flex>
            </Drawer>
        </>
    );
}

export { EventDrawerTreatment, formatDate }
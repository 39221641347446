import React, { useEffect, useState } from 'react';
import { Card, Divider, Flex, message, Typography } from 'antd';
import { FaCat, FaDog } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from '../../../state/globalState';
import { getEventsFromUser } from '../../../request/events';
import { EventDrawerAppointment } from '../drawer/AppointmentDrawer';
import { EventDrawerTreatment } from '../drawer/TreatmentDrawer';
import { customTheme } from '../../../config/theme';
import { isImportant } from '../CalendarDisplay';
import PetAvatar, { assignPetAvatar } from '../../pets/PetAvatar';

function ComingEvents() {
  const {t} = useTranslation();

  const [reRender, setReRender] = useGlobalState('reRender');

  const [commingEvent, setCommingEvent] = useState([]);
  const [drawerEvent, setDrawerEvent] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      getComingEvent();
    }
  }

  const getTranslatedEventType = (type: string) => {
    const appointmentTranslateSearch = `appointment-creation.appointment-type.${type}`
    const treatmentReminderTranslateSearch = `treatment-reminder-creation.treatment-reminder-type.${type}`

    if (appointmentTranslateSearch != t(appointmentTranslateSearch)) {
      return t(appointmentTranslateSearch);
    } else if (treatmentReminderTranslateSearch != t(treatmentReminderTranslateSearch)) {
      return t(treatmentReminderTranslateSearch);
    }
    return type;
  }

  const getComingEvent = async () => {
    const response = await assignPetAvatar(await getEventsFromUser(), "pet.pet_id", "pet.avatar", true);

    const formatDateToIso = (dateToFormat: string) => {
      const [date, time] = dateToFormat.split(' ');
      const [day, month, year] = date.split('-');

      return `${year}-${month}-${day}T${time || "00:00"}:00Z`;
    }

    response.sort((a: any, b: any) => new Date(formatDateToIso(a.date)).getTime() - new Date(formatDateToIso(b.date)).getTime());
    let filteredResponse = response.filter((event: any) => new Date(formatDateToIso(event.date || "")).getTime() >= new Date().getTime());

    filteredResponse = filteredResponse.map((event: any) => {
      event.important = isImportant(event, t);
      return event;
    });

    setCommingEvent(filteredResponse);
  }

  useEffect(() => {
    getComingEvent()
  }, [reRender]);

  useEffect(() => {
    commingEvent.forEach((event: any) => {
      if (event.length === 0)
        setCommingEvent((prevEvents) => prevEvents.filter((e: any) => e !== event));
    });
  }, [commingEvent]);

  return (
    <>
      {openStateDrawer &&
          <>
              {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
                  <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={getComingEvent}/>
              ) : (
                  <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={getComingEvent}/>
              )}
          </>
      }
      {commingEvent.length !== 0 &&
        <Flex style={{ margin: '0 auto', maxWidth: '620px', marginTop: '10px', flexDirection: 'column' }}>
          <Typography.Title level={5} style={{ textAlign: 'center', margin: 0 }}>{t("event-page.coming-event")}</Typography.Title>
          {commingEvent.slice(0, 3).map((event: any, key: any) => (
            <Card
                style={{
                  height: 'auto', cursor: 'pointer', minWidth: '100px', textAlign: 'left', margin: '10px', marginRight: '15px', marginLeft: '15px',
                  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                }}
                onClick={() => {
                    setDrawerEvent(event);
                    setOpenStateDrawer(true)
                }}
                hoverable
            >
              <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                <Typography.Text style={{ fontSize: '17px' }}>{(event?.date).split(" ")[0]} - {getTranslatedEventType(event?.name)}</Typography.Text>
                {event.important && (
                  <ExclamationCircleOutlined
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                  />
                )}
              </Flex>
            </Card>
          ))}
          <Flex style={{ marginTop: '25px', marginBottom: '15px', width: '50%', alignSelf: 'center', height: '2px' }}>
            <Divider style={{ margin: 0, backgroundColor: 'rgba(173, 174, 175, 0.2)' }}/>
          </Flex>
        </Flex>
      }
    </>
  );
}

export default ComingEvents;

import { useTranslation } from "react-i18next";

function BehaviorData() {
    const {t} = useTranslation();

    return [
        {
            label: t("behaviors.list.everything-is-fine"),
            value: '',
            id: 0
        },
        {
            label: t("behaviors.list.hunger"),
            details: [
                { key: `${t("behaviors.list.hunger")} 0`, label: '0', id: 1 },
                { key: `${t("behaviors.list.hunger")} --`, label: '--', id: 2 },
                { key: `${t("behaviors.list.hunger")} ++`, label: '++', id: 3 },
            ],
            value: ''
        },
        {
            label: t("behaviors.list.hydration"),
            details: [
                { key: `${t("behaviors.list.hydration")} 0`, label: '0', id: 4 },
                { key: `${t("behaviors.list.hydration")} --`, label: '--', id: 5 },
                { key: `${t("behaviors.list.hydration")} ++`, label: '++', id: 6 },
            ],
            value: ''
        },
        {
            label: t("behaviors.list.energy"),
            details: [
                { key: `${t("behaviors.list.energy")} 0`, label: '0', id: 7},
                { key: `${t("behaviors.list.energy")} --`, label: '--', id: 8 },
                { key: `${t("behaviors.list.energy")} ++`, label: '++', id: 9 },
            ],
            value: ''
        },
        {
            label: t("behaviors.list.irritated"),
            value: '',
            id: 10
        },
        {
            label: t("behaviors.list.scratching++"),
            value: '',
            id: 11
        },
        {
            label: t("behaviors.list.licking++"),
            value: '',
            id: 12
        },
        {
            label: t("behaviors.list.hurt"),
            value: '',
            id: 13
        },
        {
            label: t("behaviors.list.eye"),
            details: [
                { key: `${t("behaviors.list.eye")} ${t("behaviors.list.closed")}`, label: t("behaviors.list.closed"), id: 14 },
                { key: `${t("behaviors.list.eye")} ${t("behaviors.list.weepy")}`, label: t("behaviors.list.weepy"), id: 15 },
            ],
            value: ''
        },
        {
            label: t("behaviors.list.breathing"),
            details: [
                { key: `${t("behaviors.list.breathing")} ${t("behaviors.list.heavy")}`, label: t("behaviors.list.heavy"), id: 16 },
                { key: `${t("behaviors.list.breathing")} ${t("behaviors.list.short")}`, label: t("behaviors.list.short"), id: 17 },
            ],
            value: ''
        },
        {
            label: t("behaviors.list.coughs"),
            value: '',
            id: 18
        },
        {
            label: t("behaviors.list.vomits"),
            value: '',
            id: 19
        },
        {
            label: t("behaviors.list.diarrhea"),
            value: '',
            id: 20
        },
        {
            label: t("behaviors.list.limps"),
            value: '',
            id: 21
        },
        {
            label: t("behaviors.list.whines"),
            value: '',
            id: 22
        },
        {
            label: t("behaviors.list.other"),
            value: '',
            id: 23
        }
    ]
}

export default BehaviorData;
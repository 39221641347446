import React from 'react';

import Behaviors from '../../component/behavior/Behaviors';
import Activity from '../../component/activity/Activity';
import DisplayWeight from '../../component/weight/DisplayWeight';
import ComingEvents from '../../component/calendar/comingEvents/ComingEvents';
import { useGlobalState } from '../../state/globalState';
import { Flex, Typography } from 'antd';
import AddPet from '../../component/pets/AddPet';
import { useTranslation } from 'react-i18next';

function Application() {
  const {t} = useTranslation();

  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');

  return (
    <>
      {numberOfPets >= 1
        ? <>
            <ComingEvents/>
            <Behaviors/>
            <DisplayWeight/>
            <Activity/>
          </>
        : <Flex
            justify='center'
            style={{ flexDirection: 'column', alignItems: 'center', height: '50vh' }}
            gap={5}
          >
            <Typography.Title
              level={1}
              style={{ fontWeight: '300', fontFamily: 'Montserrat, sans-serif', margin: 0 }}
            >
              {t("first-connection.welcome")}
            </Typography.Title>
            <Typography.Text
              style={{ fontSize: '22px', fontFamily: 'Montserrat, sans-serif', width: '200px', textAlign: 'center', fontWeight: '300' }}
            >
              {t("first-connection.add-pet")}
            </Typography.Text>
            <Flex style={{  }}>
              <AddPet setStyle={false}/>
            </Flex>
          </Flex>
      }
    </>
  );
}

export default Application;

import axios from "axios";
import { ImageUploader } from "../component/imageUploader/ImageUploader";

const createImageFromUrl = async (presignedUrl: string, mimeType: string, file: any) => {
    try {
      const response = await axios.put(presignedUrl, file,
        {
          headers: {
            'Content-Type': mimeType
          }
        }
      );
      return response;
    } catch (err: any) {
      throw err;
    }
}

export {
    createImageFromUrl,
}
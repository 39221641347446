import axios from 'axios';

const getAllActivityFromPet = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/all`,
        {
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
    }
}

const getTodayActiviyFromPet = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/today`,
      {
        withCredentials: true
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
    return null;
  }
}

const deleteSingleActivity = async (id: string, index: number) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}/${index}`,
      {
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const deleteActivity = async (id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}`,
      {
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const createActivity = async (activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/activity `,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const updateTodayActivity = async (activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/activity/today`,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const updateActivity = async (activity: {created_at: string, duration: string, id: number}, id: number) => {
  try {
    const data = JSON.stringify({activity, id}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/activity `,
      data,
      {
          headers: {
              "Content-Type": "application/json"
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

export {
  getTodayActiviyFromPet,
  getAllActivityFromPet,
  createActivity,
  deleteActivity,
  deleteSingleActivity,
  updateTodayActivity,
  updateActivity
}
import React, { useState } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { updateUserPassword } from '../../../request/user/user';
import { useTranslation } from 'react-i18next';

function PasswordModal(props: any) {
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateUserPassword(values.password, values.oldPassword);
            if (response && response?.status !== 200) {
                message.error(t("my-profile.password-modification.error"));
            } else {
                message.success(t("my-profile.password-modification.success"));
            }
            form.resetFields();
            props.onFinish();
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    };

    const handleCancel = () => {
        props.onFinish();
    };

    return (
        <>
            <Modal
                title={t("my-profile.password-modification.change")}
                open={true}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t("form.confirm")}
                cancelText={t("form.cancel")}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="changePassword"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label={t("my-profile.password-modification.old-password")}
                        name="oldPassword"
                        rules={[{ required: true, message: t("my-profile.password-modification.old-password-placeholder") }]}
                    >
                        <Input.Password placeholder={t("my-profile.password-modification.old-password-placeholder")} />
                    </Form.Item>
                    <Form.Item
                        label={t("my-profile.password-modification.new-password")}
                        name="password"
                        rules={[
                            { required: true, message: t("my-profile.password-modification.new-password-placeholder") },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*\d).{8,}$/,
                                message: t("my-profile.password-modification.new-password-pattern"),
                            },
                        ]}
                    >
                        <Input.Password placeholder={t("my-profile.password-modification.new-password-placeholder")} />
                    </Form.Item>
                    <Form.Item
                        label={t("my-profile.password-modification.confirm-new-password")}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: t("my-profile.password-modification.confirm-new-password-placeholder") },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t("my-profile.password-modification.match-new-password")));
                                },
                            })
                        ]}
                    >
                        <Input.Password placeholder={t("my-profile.password-modification.confirm-new-password-placeholder")} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PasswordModal;

import React, { useState } from 'react';

import PetsDisplay from '../../component/pets/PetDisplay';
import AddPet from '../../component/pets/AddPet';

function Pets() {
  const [petsRenew, setPetsRenew] = useState<{date: Date, renew: boolean}>({date: new Date(), renew: false});

  return (
    <div className="Pets">
        <PetsDisplay renew={petsRenew}/>
        <AddPet renew={() => setPetsRenew({date: new Date(), renew: true})} setStyle={true}/>
      </div>
  );
}

export default Pets;

import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Pause } from "@mui/icons-material";
import { Flex, Typography } from "antd";
import { act, useEffect, useState } from "react";
import { Area, Bar, BarChart, CartesianGrid, ComposedChart, Legend, Line, LineChart, Rectangle, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from "recharts";
import { useTranslation } from "react-i18next";

import { customTheme } from "../../../config/theme";

function ActivityGraph({ activity }: any) {
    const {t} = useTranslation()
    const convertTimeToSeconds = (timeString: any) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        return hours * 3600 + minutes * 60 + seconds;
    };

    const transformedData = activity && activity.map((entry: any) => ({
        ...entry, activity: convertTimeToSeconds(entry.duration),
    }));

    const CustomTooltipActivity = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const { _id, created_at } = payload[0].payload;
            const activityTime = activity.find((_activity: any) => _activity._id === _id).duration;

            return (
                <Flex className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', flexDirection: 'column' }}>
                    <Typography.Text>{`${created_at}`}</Typography.Text>
                    <Typography.Text style={{ color: customTheme.colorPrimary }}>{`${t("activity-section.activity-tooltip")}: ${activityTime}`}</Typography.Text>
                </Flex>
            );
        }
        return (
            null
        );
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '160px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={550}
                    height={200}
                    data={transformedData}
                    className='weight-graph'
                    style={{ maxWidth: '600px', minWidth: '100px' }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {transformedData && transformedData.length != 0 &&
                        <XAxis dataKey="created_at" hide/>
                    }
                    <Tooltip content={<CustomTooltipActivity/>}/>
                    <Bar dataKey="activity" fill={customTheme.colorPrimary} activeBar={<Rectangle fill={customTheme.colorPrimary} stroke={customTheme.colorPrimary} />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ActivityGraph;
import { Button, Card, ConfigProvider, Divider, Flex, Form, Input, Layout, List, Popover, Space, Tabs, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';

import './profile.css'
import { updateUserEmail, updateUser, updateUserPassword } from '../../request/user/user';
import { getVetFromUser } from '../../request/vet';
import VetsDisplay from './VetsDisplay';
import PasswordModal from './modals/PasswordModal';
import { customTheme } from '../../config/theme';
import EmailModal from './modals/EmailModal';
import ChangeLang from '../lang/ChangeLang';
import { useTranslation } from 'react-i18next';
import { logout } from '../../request/auth';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../state/globalState';
import FeedbackButton from '../utils/FeedbackButton';

function ProfileDisplay(props: any) {
    const {t} = useTranslation();

    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

    const [form] = Form.useForm();
    const [formEmail] = Form.useForm();
    const [formPassword] = Form.useForm();

    const user = props.user;

    const [save, setSave] = useState<boolean>(false);
    const [disableSave, setDisableSave] = useState(false);

    const [firstname, setFirstname] = useState("");
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordModalVisibility, setPasswordModalVisibility] = useState(false);
    const [emailModalVisibility, setEmailModalVisibility] = useState(false);

    const navigate = useNavigate();

    const handleSubmitProfile = async () => {
        try {
            const result = await updateUser(
                firstname !== "" ? firstname : user.firstname,
                username !== "" ? username : user.username,
                phoneNumber !== "" ? phoneNumber : (user?.phone_number || "")
            );
            if (result && result?.status === 200 && result?.data.msg === 'User updated') {
                message.success(t("my-profile.success"));
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to modify user information') {
                message.error(t("my-profile.error"));
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

  return (
    <ConfigProvider
        theme={{
            components: {
                Tabs: {
                    itemSelectedColor: customTheme.colorPrimary,
                    inkBarColor: customTheme.colorPrimary,
                    itemHoverColor: 'rgba(0, 123, 255, 0.8)',
                    itemColor: 'black'
                },
            }
        }}
    >
        <Layout>
            <Layout.Content style={{ backgroundColor: '#E6F7FF' }}>
                    <Tabs
                        centered
                        className='profile-tabs'
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: t("my-profile.title"),
                                children:
                                    <>
                                        {user &&
                                            <div style={{maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <Divider className='profil-divider'>{t("my-profile.my-profile")}</Divider>
                                                <Form
                                                    form={form}
                                                    layout='vertical'
                                                    onFinish={() => handleSubmitProfile()}
                                                    style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                                                    initialValues={{ firstname: user.firstname, username: user.username, phone_number: user.phone_number || ""}}
                                                >
                                                    <div className='profile-container-responsive' style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name="firstname"
                                                            label={t("my-profile.firstname")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        >
                                                            <Input
                                                                value={firstname}
                                                                onChange={(e) => {
                                                                    setDisableSave(e.target.value == '')
                                                                    setFirstname(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div style={{ width: '4%' }}/>
                                                        <Form.Item
                                                            name="username"
                                                            label={t("my-profile.username")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        >
                                                            <Input
                                                                value={username}
                                                                onChange={(e) => {
                                                                    setDisableSave(e.target.value == '')
                                                                    setUsername(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='profile-container-responsive' style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name="phone_number"
                                                            label={t("my-profile.phone-number")}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                        >
                                                            <Input
                                                                value={phoneNumber}
                                                                onChange={(e) => {
                                                                    setPhoneNumber(e.target.value)
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div style={{ flexGrow: 1, alignContent: 'center'}}>
                                                            <ChangeLang/>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <Divider className='profil-divider'>{t("my-profile.personal-information")}</Divider>
                                                <Form
                                                    form={formEmail}
                                                    layout='vertical'
                                                    className='profile-container-responsive'
                                                    style={{ display: 'flex', padding: '10px' }}
                                                    initialValues={{ email: user.email, password: '********' }}
                                                >
                                                    <Form.Item
                                                        name="email"
                                                        label={t("my-profile.email")}
                                                        rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        className='profile-content-responsive'
                                                        style={{ width: '48%' }}
                                                    >
                                                        <Space.Compact style={{ width: '100%' }}>
                                                            <Input
                                                                value={email === "" ? user.email : email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                style={{ width: '100%', backgroundColor: 'white', color: 'black', cursor: 'default' }}
                                                                disabled={true}
                                                            />
                                                            <Button style={{ width: '1%' }} onClick={() => setEmailModalVisibility(true)}><EditOutlined /></Button>
                                                        </Space.Compact>
                                                    </Form.Item>
                                                </Form>
                                                <div style={{ width: '4%' }}/>
                                                <Form
                                                    form={formPassword}
                                                    layout='vertical'
                                                    className='profile-container-responsive'
                                                    style={{ display: 'flex', padding: '10px' }}
                                                    initialValues={{ email: user.email, password: '********' }}
                                                >
                                                    <Form.Item
                                                        name="password"
                                                        label={t("my-profile.password")}
                                                        rules={[{ required: true, message: t("my-profile.invalid-field") }]}
                                                        className='profile-content-responsive'
                                                        style={{ width: '48%' }}
                                                    >
                                                        <Space.Compact style={{ width: '100%' }}>
                                                            <Input
                                                                style={{ width: '100%', backgroundColor: 'white', color: 'black', cursor: 'default' }}
                                                                defaultValue={"********"}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                disabled
                                                            />
                                                            <Button style={{ width: '1%' }} onClick={() => setPasswordModalVisibility(true)}><EditOutlined /></Button>
                                                        </Space.Compact>
                                                    </Form.Item>
                                                </Form>
                                                {passwordModalVisibility && <PasswordModal onFinish={() => setPasswordModalVisibility(false)}/>}
                                                {emailModalVisibility &&
                                                    <EmailModal onFinish={(email: string) => {
                                                        setEmailModalVisibility(false);
                                                        if (email)
                                                            setEmail(email);
                                                    }}/>
                                                }
                                                <Flex style={{ padding: '10px', alignItems: 'center' }} justify='start' gap={30}>
                                                    <Tooltip title={t("my-profile.logout")}>
                                                        <Button
                                                            type="default"
                                                            danger
                                                            icon={<LogoutOutlined />}
                                                            size='large'
                                                            onClick={async () => {
                                                                await logout();
                                                                navigate('/login');
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {footerVisibility &&
                                                        <FeedbackButton/>
                                                    }
                                                </Flex>
                                                {save &&
                                                    <Button
                                                        type="primary"
                                                        style={{ fontSize: '18px', position: 'fixed', bottom: '25px', right: '25px', zIndex: '500' }}
                                                        onClick={() => {
                                                            form.submit();
                                                            setSave(false)
                                                        }}
                                                        disabled={disableSave}
                                                    >
                                                        {t("form.save")}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </>
                            },
                            {
                                key: '2',
                                label: t("vet-profile.title"),
                                children:
                                    <VetsDisplay/>
                            },
                        ]}
                    />
            </Layout.Content>
        </Layout>
    </ConfigProvider>
  );
}

export default ProfileDisplay;

import { createGlobalState } from 'react-hooks-global-state'

const { useGlobalState }: any = createGlobalState(
    {
        isLogin: false,
        isLoading: false,

        userPets: [""],
        selectedPetName: "",
        numberOfPets: 0,

        reRender: "",
        reRenderSelectPet: "",

        footerVisibility: false,
    }
);

export { useGlobalState };
import React, { act, useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Form, Input, InputNumber, Radio, Segmented, Select, Typography } from "antd";
import { GoTrash } from "react-icons/go";
import { FaCat, FaDog } from "react-icons/fa";
import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

import '../activity.css'
import { deleteActivity, getAllActivityFromPet, updateActivity } from "../../../request/activity";
import { getUserSelectedPetAvatar } from "../../../request/user/pet";
import ActivityGraph from "./ActivityGraph";
import { useGlobalState } from "../../../state/globalState";
import { secondsToHms } from "../Activity";
import { ActivityDetailsDrawer } from "./ActivityDetailsDrawer";
import { dateFormat } from "../../../config/format";
import { isDateToday } from "../../weight/drawer/WeightDrawer";
import { customTheme } from "../../../config/theme";
import { useTranslation } from "react-i18next";
import { SHORTEN_MONTH } from "../../../config/define";
import { TFunction } from "i18next";
import PetAvatar, { assignPetAvatar } from "../../pets/PetAvatar";

type Props = {
    renew: () => void,
    onClose: (reRender: boolean) => void
}

type ActivityDetails = {
    _id: string
    activity: {created_at: string, duration: string, id: number}[];
    created_at: string;
    pet_id: string;
};

function formatActivity(activity: ActivityDetails[]) {
    let newActivityList: any = []

    for (let i = 0; i < activity.length; i++) {
        let seconds = 0;

        for (let j = 0; j < activity[i].activity.length; j++) {
            const [h, m, s] = activity[i].activity[j].duration.split(':');
            seconds += (Number(h) * 60 * 60 + Number(m) * 60 + Number(s));
        }
        newActivityList.push({...activity[i], duration: secondsToHms(seconds)})
    }
    return newActivityList;
}

function ActivityDrawer(props: Props) {
    const {t} = useTranslation();

    const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');

    const [open, setOpen] = useState(true);

    const [petAvatar, setPetAvatar] = useState<{species: string, avatar: string, id: string}>({species: "", avatar: "", id: ""});
    const [petActivities, setPetActivities] = useState<ActivityDetails[]>([]);

    const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
    const [activity, setActivity] = useState<dayjs.Dayjs>(dayjs());

    const [data, setData] = useState<any>(petActivities);

    const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);
    const [drawerActivity, setDrawerActivity] = useState<ActivityDetails>();
    const [drawerActivityIndex, setDrawerActivityIndex] = useState<string>("");

    const handleSubmit = async (id: number) => {
        try {
            const formattedDate = date.format(`${dateFormat}`)
            const formattedTime = activity.format('HH:mm:ss')

            // await updateActivity(formattedTime, formattedDate, id);
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        if (props.renew) {
            props.renew()
        }
        getPetActivity(false);
    }

    const getPetActivity = async (reRender: boolean=true) => {
        const response = await getAllActivityFromPet();

        const formatDate = (dateToFormat: string) => {
            const [date] = dateToFormat.split(' ');
            const [day, month, year] = date.split('-');

            return `${month}-${day}-${year}`;
        }
        response.sort((a: ActivityDetails, b: ActivityDetails) => new Date(formatDate(a.created_at)).getTime() - new Date(formatDate(b.created_at)).getTime());
        const formattedActivity = await formatActivity(response)

        setPetActivities(formattedActivity);
        setData(formattedActivity);
        if (reRender && props.renew) {
            props.renew();
        }
        if (formattedActivity && openStateDrawer) {
            setDrawerActivity(formattedActivity.find((v: ActivityDetails) => v._id === drawerActivityIndex))
        }
    }

    const handleActivityClick = (_activity: any) => {
        setDrawerActivity(_activity);
        setDrawerActivityIndex(_activity._id)
        setOpenStateDrawer(true);
    }

    const getPetAvatar = async () => {
        const response = await assignPetAvatar(await getUserSelectedPetAvatar(), "id", "avatar", false);

        setPetAvatar(response);
    }

    const onDrawerClose = (reRender: boolean) => {
        setTimeout(() => {
          setOpenStateDrawer(false)
        }, 250);
        if (reRender) {
            getPetActivity(false);
            if (props.renew) {
                props.renew();
            }
        }
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    useEffect(() => {
        getPetActivity(false)
        getPetAvatar()
    }, []);

    return (
        <>
            {openStateDrawer && drawerActivity &&
                <ActivityDetailsDrawer activity={drawerActivity} petAvatar={petAvatar} renew={getPetActivity} onClose={onDrawerClose}/>
            }
            <Drawer
                title={`${t("activity-section.activity-of")} ${selectedPetName}`}
                onClose={() => handleClose(false)}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
            >
                {petActivities && <ChoseOptionActivity activity={petActivities} setData={setData} t={t}/>}
                <div style={{ backgroundColor: 'rgba(197, 194, 194, 0.4)', borderRadius: '20px', padding: 10 }}>
                    <ActivityGraph activity={data}/>
                </div>
                <Divider/>
                <Flex gap={'13px'} style={{ flexDirection: 'column' }}>
                    {data && data.toReversed().map((_activity: any, key: any) => (
                        <Card
                            key={key}
                            onClick={() => handleActivityClick(_activity)}
                            style={{ height: 'auto', minWidth: '100px' }}
                            hoverable
                        >
                            <Flex
                                justify="space-between"
                                style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                            >
                                <Flex style={{ alignItems: 'center' }}>
                                    <PetAvatar species={petAvatar.species} url={petAvatar.avatar} iconSize={{width: "30px", height: "30px"}}/>
                                    {' '}
                                    <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                    <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                        {(_activity.created_at).split(" ")[0]}
                                        {isDateToday(_activity.created_at) ? ` (AUJOURD'HUI)` : '' } - {_activity.duration}
                                    </Typography.Text>
                                </Flex>
                                <Flex>
                                    <RightOutlined/>
                                </Flex>
                            </Flex>
                        </Card>
                    ))}
                </Flex>
            </Drawer>
        </>
    );
}

function groupByMonthYearWeek(
    activities: any,
    month: number,
    year: string,
    weeks: Date[],
    chosenOption: string,
    t: TFunction<"translate", undefined>
) {
    let grouped: any = [];

    if (chosenOption === t("time-option.all")) {
        return activities
    }
    if (chosenOption === t("time-option.year")) {
        for (let i = 0; i < activities.length; i++) {
            if (year === activities[i].created_at.split('-')[2]) {
                grouped.push(activities[i]);
            }
        }
    }
    if (chosenOption === t("time-option.month")) {
        for (let i = 0; i < activities.length; i++) {
            const date = activities[i].created_at;

            if (year === date.split('-')[2] && month + 1 === Number(date.split('-')[1])) {
                grouped.push(activities[i]);
            }
        }
    }
    if (chosenOption === t("time-option.week")) {
        for (let i = 0; i < activities.length; i++) {
            const [day, month, year] = activities[i].created_at.split('-')
            const date = new Date(`${month}-${day}-${year}`)

            if (weeks.some(weeksDate => weeksDate.getTime() === date.getTime())) {
                grouped.push(activities[i]);
            }
        }
    }
    return grouped;
}

function ChoseOptionActivity(props: any) {
    const [chosenOption, setChosenOption] = useState<string>(props.t("time-option.all"));
    const options: string[] = [props.t("time-option.all"), props.t("time-option.year"), props.t("time-option.month"), props.t("time-option.week")];

    const firstLastYear = (activities: any) => {
        if (activities.length == 0) {
            return [];
        }
        let years: {value: string, label: string}[] = [];
        const firstDate: string = activities[0].created_at.split(' ')[0];
        const lastDate: string = activities[activities.length - 1].created_at.split(' ')[0];
        const firstYear: number = Number(firstDate.split('-')[2]);
        const lastYear: number = Number(lastDate.split('-')[2]);

        for (let i = firstYear; i <= lastYear; i++) {
            years.push({value: String(i), label: String(i)});
        }
        return years.reverse();
    }

    const years: {value: string, label: string}[] = firstLastYear(props.activity);
    const weeks: any = getWeeksOfYear(Number(years[0]?.value || dayjs().year()), Number(years[years.length - 1]?.value || dayjs().year()));

    const [counterYear, setCounterYear] = useState<string>(years[0]?.label || String(dayjs().year()));
    const [counterMonth, setCounterMonth] = useState<number>(dayjs().month());
    const [counterWeek, setCounterWeek] = useState<number>(findToday(weeks));

    function findToday(weeks: any) {
        const today = new Date().setHours(0, 0, 0, 0);
        const weekIndex = weeks.findIndex((week: any) =>
            week.some((day :any) => day.setHours(0, 0, 0, 0) === today)
        );
        return weekIndex;
    }

    function getWeeksOfYear(firstYear: number, lastYear: number) {
        const weeks = [];
        let currentDate = new Date(firstYear, 0, 1);
        const dayOfWeek = currentDate.getDay();
        const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        currentDate.setDate(currentDate.getDate() + diffToMonday);

        while (currentDate.getFullYear() !== lastYear + 1) {
            const week = [];
            for (let i = 0; i < 7; i++) {
                week.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            weeks.push(week);
        }
        return weeks;
    }

    return (
        <>
            <Segmented
                options={options}
                style={{ marginBottom: '5px' }}
                block
                defaultValue={props.t("time-option.all")}
                onChange={(e) => {
                    props.setData(groupByMonthYearWeek(props.activity, counterMonth, counterYear, weeks[counterWeek], e, props.t))
                    setChosenOption(e);
                }}
            />
            {chosenOption !== props.t("time-option.all") &&
                <Flex justify='flex-end' style={{ marginBottom: '5px', alignItems: 'center' }}>
                    {chosenOption === props.t("time-option.month") &&
                        <>
                            <Button
                                icon={<ArrowLeftOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterMonth((counterMonth <= 0 ? 11 : counterMonth - 1))
                                    props.setData(groupByMonthYearWeek(props.activity, (counterMonth <= 0 ? 11 : counterMonth - 1), counterYear, weeks[counterWeek], chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                            <Flex justify='center' style={{ width: '30px' }}>
                                <Typography.Text>
                                    {SHORTEN_MONTH(props.t)[counterMonth]}
                                </Typography.Text>
                            </Flex>
                            <Button
                                icon={<ArrowRightOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterMonth((counterMonth >= 11 ? 0 : counterMonth + 1))
                                    props.setData(groupByMonthYearWeek(props.activity, (counterMonth >= 11 ? 0 : counterMonth + 1), counterYear, weeks[counterWeek], chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                        </>
                    }
                    {chosenOption === props.t("time-option.week") &&
                        <>
                            <Button
                                icon={<ArrowLeftOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterWeek((counterWeek <= 0 ? 0 : counterWeek - 1))
                                    props.setData(groupByMonthYearWeek(props.activity, counterMonth, counterYear, weeks[(counterWeek <= 0 ? 0 : counterWeek - 1)], chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                            <Flex justify='center' style={{ width: '110px' }}>
                                <Typography.Text>
                                    {weeks[counterWeek][0].getDate()} {SHORTEN_MONTH(props.t)[weeks[counterWeek][0].getMonth()]} - {weeks[counterWeek][6].getDate()} {SHORTEN_MONTH(props.t)[weeks[counterWeek][6].getMonth()]}
                                </Typography.Text>
                            </Flex>
                            <Button
                                icon={<ArrowRightOutlined style={{ fontSize: '15px' }}/>}
                                onClick={() => {
                                    setCounterWeek((counterWeek >= weeks.length - 1 ? counterWeek : counterWeek + 1))
                                    props.setData(groupByMonthYearWeek(props.activity, counterMonth, counterYear, weeks[(counterWeek >= weeks.length - 1 ? counterWeek : counterWeek + 1)], chosenOption, props.t))
                                }}
                                style={{ overflow: 'hidden', border: 'none', boxShadow: 'none', alignItems: 'center' }}
                            />
                        </>
                    }
                    {chosenOption !== props.t("time-option.week") &&
                        <Select options={years} style={{ width: 80 }} defaultValue={counterYear}
                            onChange={(e) => {
                                setCounterYear(e)
                                props.setData(groupByMonthYearWeek(props.activity, counterMonth, e, weeks[counterWeek], chosenOption, props.t))
                            }}
                        />
                    }
                </Flex>
            }
        </>
    );
}

export { ActivityDrawer }
